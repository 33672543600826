/* eslint-disable max-len, no-use-before-define, new-cap */

'use strict';

const {parseUnknown, splitVariable, mapVariable, unescape, escape, escapeable, concatenate, escapeHTML, isNumber, pathToString, typeCheck} = require('./utils.js');
const {IMLError} = require('./errors.js');
const compare = require('./compare.js');

const ALT_OPS = {
	'&&': '&',
	'||': '|',
	'==': '=',
	'===': '=',
	'!==': '!='
};

const DEFAULT_FLAGS = {
	keepUnwantedOperators: false, // Keeps 'operator' on start and end
};

/**
 * AST constructor.
 * 
 * @param {*} source 
 * @param {*} defaults 
 */

const IMLArray = (source, defaults, flags) => {
	const a = [];
	defaults = Object.assign({}, IML.defaults, defaults);
	flags = Object.assign({}, DEFAULT_FLAGS, flags);

	Object.defineProperty(a, '__imlAst__', {
		enumerable: false,
		value: true
	});

	Object.defineProperty(a, 'toJSON', {
		enumerable: false,
		value() {
			return source != null ? source : '{{}}';
		}
	});

	Object.defineProperty(a, 'push', {
		enumerable: false,
		value(node) {
			const { keepUnwantedOperators } = flags;
			if (!keepUnwantedOperators) {
				const { length } = this;

				if (node.type === 'operator' && node.name !== '!') {
					if (length === 0) {
						IML.errors.push(new IMLError('Operator on begining of an expression.'));
						return node; // don't add
					} else if (this[length - 1].type === 'operator') {
						IML.errors.push(new IMLError('Operator next to operator.'));
						return node; // don't add
					}
				}
			}

			return Array.prototype.push.call(a, node);
		}
	});

	Object.defineProperty(a, 'clone', {
		enumerable: false,
		value(deep) {
			if (deep) throw new Error('Not implemented.');

			const cloned = IMLArray(source, defaults, flags);
			for (const item of this) cloned.push(item);
			return cloned;
		}
	});

	Object.defineProperty(a, 'derive', {
		enumerable: false,
		value() {
			return IMLArray(source, defaults, flags);
		}
	});

	for (const key in defaults) {
		if (Object.prototype.hasOwnProperty.call(defaults, key)) {
			Object.defineProperty(a, key, {
				enumerable: false,
				writable: true,
				value: defaults[key]
			});
		}
	}

	return a;
};

/**
 * Integromat Markup Language. This class is singleton.
 * 
 * @property {Array} errors Array of parse/stringify errors. (static property)
 * @singleton
 */

class IML {
	static get KEYWORDS() {
		return require('./keywords.js');
	}
	static get FUNCTIONS() {
		return require('./functions.js');
	}
	static get OPERATORS() {
		return require('./operators.js');
	}
	static get VARIABLES() {
		return require('./variables.js');
	}
	static get FILTERS() {
		return require('./filters.js');
	}

	static set options(newOptions) {
		IML._options = newOptions || {};
	}
	static get options() {
		return IML._options || {};
	}

	/**
	 * Returns true if argument is AST.
	 * 
	 * @param {*} object Anything.
	 * @returns {Boolean}
	 */

	static isAST(object) {
		return (object != null ? object.__imlAst__ : undefined) && Array.isArray(object);
	}

	/**
	 * @deprecated
	 */

	static validateName(name) {
		if (!name) {
			return name != null ? name : '';
		}
		if ('number' === typeof name) {
			name = name.toString();
		}
		if ('string' !== typeof name) {
			return '';
		}

		name = name.replace(/[^_a-z0-9]/gi, '_');
		if ((/^\d/).test(name)) {
			name = `_${name}`;
		}
		return name;
	}

	/**
	 * Create AST from IML string. Function doesn't throw any synchronous or asynchronous errors, all errors
	 * raised during parse process are stored in `IML.errors` array. This array is cleared on each call.
	 * 
	 * @param {String} text IML string.
	 * @param {Object} [links] Link to ID map.
	 * @param {Object} [defaults] Defaults.
	 * @param {Object} [flags] Custom flags.
	 * @returns {Array} AST.
	 */

	static parse(text, links, defaults, flags) {
		flags = Object.assign({}, DEFAULT_FLAGS, flags);
		const { keepUnwantedOperators } = flags;

		let nodes;
		IML.errors = [];

		if (text == null) {
			return IMLArray(null, defaults, flags);
		}

		if ((typeof text === 'object') || (typeof text === 'function')) {
			if (!(text instanceof Date)) {
				IML.errors.push(new IMLError(`Expected string, got ${typeof text}.`));
				return IMLArray(null, defaults, flags);
			}

			text = text.toISOString();
		}

		if (typeof text !== 'string') {
			text = String(text);
		}

		if (text.length === 0) {
			nodes = IMLArray('', defaults, flags);
			nodes.push({
				type: 'text',
				value: ''
			});

			return nodes;

		} else if (text.indexOf('{{') === -1 || text.indexOf('}}') === -1) {
			// performance booster, ignore parsing if opening braces are not present
			nodes = IMLArray(text, defaults, flags);
			nodes.push({
				type: 'text',
				value: text
			});

			return nodes;
		}

		const { length } = text;
		let cursor = -1;
		nodes = IMLArray(text, defaults, flags);
		let buffer = '';
		let incode = false;
		let incodetimes = 0;
		let incodestart = null;
		let instring = false;
		let inescape = false;
		let escaped = '';
		let instringchar = null;
		let related; // Contains reference to "variable" when parsing variable with [] brackets.
		let errors = null;

		let parent = null; // parent function
		const parents = []; // handles nesting

		while (++cursor < length) {
			let char = text.charAt(cursor);

			if (inescape) {
				if (char !== '`') {
					escaped += char;
					buffer += char;
					continue;
				} else if (char === '`' && text.charAt(cursor + 1) === '`') {
					cursor++;
					escaped += '``';
					buffer += '``';
					continue;
				}
			}

			switch (char) {
				case '{':
					if (!instring && !incode && text.charAt(cursor + 1) === '{') {
						incode = true;
						incodetimes++;
						related = null;
						errors = [];

						while (text.charAt(++cursor + 1) === '{') {
							buffer += '{';
						}

						if (buffer.length) {
							nodes.push({
								type: 'text',
								value: buffer
							});
						}

						incodestart = { cursor: cursor - 1, node: nodes.length }; // Also include opening brackets
						buffer = '';
					} else if (!instring && incode && text.charAt(cursor + 1) === '{') {
						// When we reach `{{` inside a code, we have probably found unlosed IML block.
						// Let's transform that IML block into text and open a new IML block.
						related = null;
						errors = [];
						parent = null;
						if (parents.length) parents.splice(0, parents.length);

						// Slice out the parsed code AST
						nodes = nodes.slice(0, incodestart.node);

						while (text.charAt(++cursor + 1) === '{') {
							// do nothing, just bump the cursor
						}

						// Add already parsed code to buffer
						if (nodes.length) {
							if (nodes[nodes.length - 1].type === 'text') {
								nodes[nodes.length - 1].value += text.substring(incodestart.cursor, cursor - 1);
							} else {
								nodes.push({
									type: 'text',
									value: text.substring(incodestart.cursor, cursor - 1)
								});
							}
						} else {
							nodes.push({
								type: 'text',
								value: text.substring(incodestart.cursor, cursor - 1)
							});
						}

						incodestart = { cursor: cursor - 1, node: nodes.length }; // Also include opening brackets
						buffer = '';
					} else {
						buffer += char;
					}
					break;

				case '}':
					if (!instring && incode && (text.charAt(cursor + 1) === '}')) {
						cursor++;
						incode = false;
						incodestart = null;

						// Propagate buffered errors
						IML.errors.push(...errors);
						errors = [];

						if (parent) {
							// some function remain unclosed
							IML.errors.push(new IMLError(`Unclosed function at position ${cursor - 1}.`));
						}

						// remove white space
						buffer = buffer.trim();

						if (buffer.length) {
							((related ? related.path : null) || parent || nodes).push(parseUnknown(buffer, links, IML.errors));
							related = null;
						}

						buffer = '';
						related = null;

					} else {
						buffer += char;
					}
					break;

				case '(':
					if (!instring && incode) {
						// remove white space
						buffer = buffer.trim();

						// move parent to parents to save nesting
						if (parent) {
							// oldparent = parent;
							parents.push(parent);
						}

						// Current parent is going to be arguments
						const args = IMLArray(null, defaults, flags);
						args.type = 'argument';

						const fce = {
							type: 'function',
							name: buffer,
							arguments: [args]
						};

						(parent || nodes).push(fce);
						parent = args;
						parents.push(fce);

						// reset related as we're jumping into the function
						related = null;
						buffer = '';

					} else {
						buffer += char;
					}
					break;

				case '[':
					if (!instring && incode) {
						// remove white space
						buffer = buffer.trim();

						if (!buffer.length && !related) {
							errors.push(new IMLError(`Unexpected [ at ${cursor - 1}.`));
						}

						// move parent to parents to save nesting
						if (parent) {
							// oldparent = parent;
							parents.push(parent);
						}

						// Current parent is going to be property
						const property = IMLArray(null, defaults, flags);
						property.type = 'property';

						if (related) {
							// This bracket as another bracket in variable being currently parsed
							if (buffer.length) related.path.push(parseUnknown(buffer, links, IML.errors));
							related.path.push(property);
							parent = property;
							parents.push(related);
						} else {
							const variable = parseUnknown(buffer, links, IML.errors);
							variable.path = [{
								type: 'variable',
								name: variable.name
							}, property];

							Object.defineProperty(variable, 'name', {
								get: pathToString
							});

							(parent || nodes).push(variable);
							parent = property;
							parents.push(variable);
						}

						buffer = '';

					} else {
						buffer += char;
					}
					break;

				case ')': case ']':
					if (!instring && incode) {
						if (parent) {
							// remove white space
							buffer = buffer.trim();

							if (buffer.length) {
								// If buffer not related or it's clearly an argument
								if (!related || parent.length < 1) {
									// add argument if we have anything in buffer
									parent.push(parseUnknown(buffer, links, IML.errors));
								} else { // ... else it's a part of variable path, so add it and clear the related, as we're at the end of the wrapping braces
									((related ? related.path : null) || parent || nodes).push(parseUnknown(buffer, links, IML.errors));
									related = null;
								}

							} else {
								if (parent[parent.length - 1] && parent[parent.length - 1].type === 'operator' && !keepUnwantedOperators) {
									parent.pop(); // remove operator
									errors.push(new IMLError('Operator on end of an expression.'));
								}
							}

							buffer = '';
							parent = null;

							if (parents.length) {
								if (char === ']' && text.charAt(cursor + 1) === '.') {
									// The variable's path continues
									related = parents.pop();
									cursor++; // Move cursor so we skip the . char
								} else if (char === ']' && text.charAt(cursor + 1) === '[') {
									// The variable's path continues
									related = parents.pop();
								} else {
									parents.pop();
									// We're jumping out of the path, so reset related
									related = null;
								}

								// go up by two levels (because arguemnts are nested in array an path in variable)
								parent = parents.pop();
							}

						} else {
							errors.push(new IMLError(`Unexpected function close at position ${cursor - 1}.`));
						}

					} else {
						buffer += char;
					}
					break;

				case '+': case '-': case '*': case '/': case '=': case '!': case '&': case '|': case '>': case '<': case '%':
					if (char === '-') {
						if (isNumber(text.charAt(cursor + 1)) && (/^\s*$/).test(buffer)) {
							buffer += char; // minus sign
							continue;
						}
					} else if (char === '!') {
						if ('=' === text.charAt(cursor + 1)) {
							char += text.charAt(++cursor);

							if ('=' === text.charAt(cursor + 1)) {
								char += text.charAt(++cursor); // allow up to three chars
							}
						}
					} else if ((char === '+') && ('+' === text.charAt(cursor + 1))) {
						char += text.charAt(++cursor);
					} else if ((char === '<') && ('=' === text.charAt(cursor + 1))) {
						char += text.charAt(++cursor);
					} else if ((char === '>') && ('=' === text.charAt(cursor + 1))) {
						char += text.charAt(++cursor);
					} else if ((char === '&') && ('&' === text.charAt(cursor + 1))) {
						char += text.charAt(++cursor);
					} else if ((char === '|') && ('|' === text.charAt(cursor + 1))) {
						char += text.charAt(++cursor);
					} else if ((char === '=') && ('=' === text.charAt(cursor + 1))) {
						if ('=' === text.charAt(cursor + 2)) {
							char += text.charAt(++cursor); // allow up to three chars
						}

						char += text.charAt(++cursor);
					}

					if (!instring && incode) {
						// remove white space
						buffer = buffer.trim();

						if (buffer.length) {
							((related ? related.path : null) || parent || nodes).push(parseUnknown(buffer, links, IML.errors));
							related = null;
						}

						(parent || nodes).push({
							type: 'operator',
							name: ALT_OPS[char] || char
						});

						buffer = '';
					} else {
						buffer += char;
					}
					break;

				case ';': case ',':
					if (!instring && incode) {
						if ((parent != null ? parent.type : undefined) === 'argument') {
							// remove white space
							buffer = buffer.trim();

							if (buffer.length) {
								// add argument if we have anything in buffer
								((related ? related.path : null) || parent || nodes).push(parseUnknown(buffer, links, IML.errors));
								related = null;
							}

							buffer = '';

							// setup new argument
							parent = [];
							parent.type = 'argument';

							// add argument to parent function
							parents[parents.length - 1].arguments.push(parent);

						} else {
							errors.push(new IMLError(`Unexpected argument separator at position ${cursor - 1}.`));
						}

					} else {
						buffer += char;
					}
					break;

				case '"': case '\'':
					if (incode) {
						// we're inside iml code

						if (instring && (char !== instringchar)) {
							buffer += char;
							continue;
						}

						if (!instring) {
							// string start
							instring = true;
							instringchar = char;

							buffer = buffer.trim();

							if (buffer.length) {
								// add argument if we have anything in buffer
								((related ? related.path : null) || parent || nodes).push(parseUnknown(buffer, links, IML.errors));
								related = null;
							}

							buffer = '';
						} else if (char === text.charAt(cursor + 1)) {
							// quot escape "" or ''
							buffer += char;
							cursor++;
						} else {
							// string end
							instring = false;
							instringchar = null;

							// parse string
							(parent || nodes).push({ type: 'string', value: buffer });

							buffer = '';
						}

					} else {
						buffer += char;
					}
					break;

				case ' ':
					if (!instring && incode) {
						buffer = buffer.trim();

						if (buffer.length) {
							// add argument if we have anything in buffer
							((related ? related.path : null) || parent || nodes).push(parseUnknown(buffer, links, IML.errors));
							related = null;
						}

						buffer = '';

					} else {
						buffer += char;
					}
					break;

				case '`':
					if (!instring && incode) {
						if (inescape) {
							// check whether the escaping has sense
							if (!escapeable(escaped)) {
								buffer = buffer.substr(0, buffer.length - (escaped.length + 1)) + escaped;
								inescape = false;
								escaped = '';
								continue;
							}
						}

						inescape = !inescape;
						escaped = '';
					}

					buffer += char;
					break;

				default:
					buffer += char;
			}
		}

		if (incode) {
			// The code was not properly closed by `}}`, let's treat the code as a text.

			// Slice out the parsed code AST
			nodes = nodes.slice(0, incodestart.node);

			// Add already parsed code to buffer
			if (nodes.length) {
				if (nodes[nodes.length - 1].type === 'text') {
					nodes[nodes.length - 1].value += text.substr(incodestart.cursor);
				} else {
					nodes.push({
						type: 'text',
						value: text.substr(incodestart.cursor)
					});
				}
			} else {
				nodes.push({
					type: 'text',
					value: text.substr(incodestart.cursor)
				});
			}

			buffer = '';
		}

		if (buffer.length) {
			nodes.push({
				type: 'text',
				value: buffer
			});

		} else {
			if (!keepUnwantedOperators) {
				if (nodes[nodes.length - 1] && nodes[nodes.length - 1].type === 'operator') {
					nodes.pop(); // remove operator
					errors.push(new IMLError('Operator on end of an expression.'));
				}
			}
		}

		// trim

		if (incodetimes === 1) {
			if (nodes.length && (nodes[0].type === 'text') && /^\s*$/.test(nodes[0].value)) {
				nodes.shift();
			}
			if (nodes.length && (nodes[nodes.length - 1].type === 'text') && /^\s*$/.test(nodes[nodes.length - 1].value)) {
				nodes.pop();
			}
		}

		IML.errors.push(...errors);

		return nodes;
	}

	/**
	 * Create IML string from AST. Function doesn't throw any synchronous or asynchronous errors, all errors
	 * raised during stringify process are stored in `IML.errors` array. This array is cleared on each call.
	 * 
	 * @param {Object} text AST.
	 * @param {Object} [links] Link to ID map.
	 * @param {Object} [flags] Custom flags.
	 * @returns {String} IML string.
	 */

	static stringify(value, links, flags = {}) {
		flags = Object.assign({}, DEFAULT_FLAGS, flags);
		const { keepUnwantedOperators } = flags;
		IML.errors = [];
		let incode = false;

		const iterate = (nodes, level) => {
			if (level == null) {
				level = 1;
			}
			let code = '';
			let last;

			for (let index = 0; index < nodes.length; index++) {
				const node = nodes[index];
				switch (node.type) {
					case 'variable':
						if (incode) {
							// only mathematical and logical operators can join code together
							if (level === 1 && last.type !== 'operator') {
								code += '}}{{';
							}
						} else {
							incode = true;
							code += '{{';
						}

						if (node.path) {
							code += node.path.map((item, index) => {
								if (item.type === 'variable') return (index === 0 ? '' : '.') + item.name;
								if (item.type !== 'property') return ''; // This should never happen

								if (item.length === 0) return '[]';
								return `[${iterate(item, level + 1)}]`;
							}).join('');
						} else {
							code += node.name;
						}
						break;

					case 'link':
						if (incode) {
							// only mathematical and logical operators can join code together
							if (level === 1 && last.type !== 'operator') {
								code += '}}{{';
							}
						} else {
							incode = true;
							code += '{{';
						}

						if (links != null) {
							code += node.name.replace((/\$([^.]+)/g), (a, b) => {
								if (!links[b]) {
									return b;
								}
								return links[b];
							});
						} else {
							code += node.name;
						}
						break;

					case 'function':
						if (incode) {
							// only mathematical and logical operators can join code together
							if (level === 1 && last.type !== 'operator') {
								code += '}}{{';
							}
						} else {
							incode = true;
							code += '{{';
						}

						code += `${node.name}(`;
						code += node.arguments.map(item => iterate(item, level + 1)).join('; ');
						code += ')';
						break;

					case 'operator':
						if (!incode) {
							incode = true;
							code += '{{';
							if (!keepUnwantedOperators) {
								if (node.name === '!') {
									code += '!';
								}
							} else {
								code += node.name;
							}
						} else {
							// only mathematical and logical operators can join code together
							if (level === 1 && last.type !== 'operator' && node.name === '!') {
								code += '}}{{';
							} else {
								const lastChar = code.charAt(code.length - 1);
								if (node.name === '!' && code.length && lastChar !== ' ' && lastChar !== '!') {
									code += ' ';
								}
							}

							if (node.name === '!') {
								code += '!';
							} else {
								code += ` ${node.name} `;
							}
						}
						break;

					case 'text':
						if (incode) {
							incode = false;
							code += '}}';
						}

						code += node.value;
						break;

					case 'string': case 'number': case 'keyword':
						if (incode) {
							// only mathematical and logical operators can join code together
							if (level === 1 && last.type !== 'operator') {
								code += '}}{{';
							}
						} else {
							incode = true;
							code += '{{';
						}

						if (node.type === 'string') {
							code += `\"${node.value.replace(/\"/g, '""')}\"`;
						} else if (node.type === 'number') {
							code += node.value.toString();
						} else {
							code += node.name;
						}
						break;
				}

				last = node;
			}

			return code;
		};

		let stringified = iterate(value);

		if (incode) {
			stringified += '}}';
		}

		return stringified;
	}

	/**
	 * Execute AST and return computed value.
	 * 
	 * Options:
	 * - **concat** - Function to be used to concatenate values to string. Different for GUI and Core. GUI version by default.
	 * - **functions** - Collection on additional functions.
	 * - **passthrough** - If true, values from mapping are passed through. If false, undefined values are converted to null. False by default.
	 * - **timezone** - Overrides timezone configuration in AST.
	 * 
	 * @param {Object} ast AST generated by `IML.parse`.
	 * @param {Object} data Collection of variables.
	 * @param {Object} [options] Options.
	 * @returns {*} Computed value.
	 */

	static execute(ast, data, options) {
		if (!options) options = {};

		// Apply global options
		options = Object.assign({}, IML.options, options);

		if (!ast) return options.passthrough ? undefined : null;
		if (!IML.isAST(ast)) throw new IMLError('Invalid AST!');

		const context = {
			timezone: options.timezone || ast.timezone,
			passthrough: options.passthrough || false
		};

		if (!ast.length) return options.passthrough ? undefined : null;

		const evaluate = (node) => {
			let func;
			switch (node.type) {
				case 'link':
					throw new IMLError(`Found link '${node.name}' is AST!`);

				case 'variable':
					if (IML.VARIABLES[node.name]) {
						if (IML.VARIABLES[node.name].value instanceof Function) {
							return IML.VARIABLES[node.name].value();
						} else {
							// system variable
							return IML.VARIABLES[node.name].value;
						}
					} else {
						// user variable
						let path;
						if (node.path) {
							path = node.path.map((item, index) => {
								if (item.type === 'variable') return (index === 0 ? '' : '.') + item.name;
								if (item.type !== 'property') return ''; // This should never happen

								if (item.length === 0) return (index === 0 ? '' : '.') + '`1`';
								return (index === 0 ? '' : '.') + escape(`${iterate(item)}`);
							}).join('');
						} else {
							path = node.name;
						}

						return mapVariable.call(context, data, path);
					}

				case 'function':
					if (options.functions && Object.prototype.hasOwnProperty.call(options.functions, node.name)) {
						func = options.functions[node.name];
					} else if (ast.functions && Object.prototype.hasOwnProperty.call(ast.functions, node.name)) {
						func = ast.functions[node.name];
					} else if (Object.prototype.hasOwnProperty.call(IML.FUNCTIONS, node.name)) {
						func = IML.FUNCTIONS[node.name].value;
					} else {
						func = undefined;
					}

					if (!func) {
						throw new IMLError(`Function '${node.name}' not found!`);
					}

					if (func === Function || func === eval) {
						throw new IMLError(`Function '${node.name}' is not executable!`);
					}

					try {
						let args;
						if (node.name === 'if') {
							args = node.arguments.slice(0);
							if (args.length) {
								args[0] = iterate(args[0]);
							}
							return iterate(func.apply(context, args));
						} else if (node.name === 'ifempty') {
							args = node.arguments.slice(0);
							if (args.length) {
								args[0] = iterate(args[0]);
							}
							const res = func.apply(context, args);
							if (res === args[0]) {
								return res;
							}
							return iterate(res);
						} else {
							args = (node.arguments || []).map((arg) => iterate(arg));
							return func.apply(context, args);
						}
					} catch (ex) {
						const err = new IMLError(`Function '${node.name}' finished with error! ${ex.message}`);
						err.imtInternalError = ex;
						throw err;
					}

				case 'text': case 'string': case 'number':
					return node.value;

				case 'keyword':
					if ('function' === typeof IML.KEYWORDS[node.name].value) {
						return IML.KEYWORDS[node.name].value();
					}

					return IML.KEYWORDS[node.name].value;
			}

			return options.passthrough ? undefined : null;
		};

		const operate = (operation, lhs, higherPrecedenceOnly = false) => {
			while (operation.length) {
				const op = operation.shift();
				let rhs = operation.shift();

				while (operation.length && operation[0].precedence > op.precedence) {
					rhs = operate(operation, rhs, true);
				}

				if (higherPrecedenceOnly) return op.value(lhs, rhs);
				lhs = op.value(lhs, rhs);
			}

			return lhs;
		};

		const iterate = (nodes) => {
			// evaluate first

			if (!Array.isArray(nodes)) {
				return options.passthrough ? undefined : null;
			}

			let index = 0;
			const res = [];

			while (index < nodes.length) {
				let node = nodes[index++];

				if (node.type === 'operator' && node.name !== '!') {
					// operator can't stand on the begining of the line
					if (res.length) {
						const operation = [res.pop()];

						while (node && node.type === 'operator') {
							if (!IML.OPERATORS[node.name]) {
								throw new IMLError(`Operator '${node.name}' not found!`);
							}

							operation.push(IML.OPERATORS[node.name]);

							// Move to right-hand-side operand
							node = nodes[index++];

							// Consume not operators
							let negate = false;
							let doubleNegate = false;

							while (node && node.type === 'operator' && node.name === '!') {
								if (negate) doubleNegate = !doubleNegate;
								negate = true;
								node = nodes[index++];
							}

							let evaluated = evaluate(node);
							if (negate) evaluated = !evaluated;
							if (doubleNegate) evaluated = !evaluated;
							operation.push(evaluated);

							// Move to next node
							node = nodes[index++];
						}

						res.push(operate(operation, operation.shift()));
						index--;
					}
				} else {
					// Consume not operators
					let negate = false;
					let doubleNegate = false;

					while (node && node.type === 'operator' && node.name === '!') {
						if (negate) doubleNegate = !doubleNegate;
						negate = true;
						node = nodes[index++];
					}

					if (!node) continue;
					if (node.type === 'operator') throw new Error('Operator next to not operator.');

					let evaluated = evaluate(node);
					if (negate) evaluated = !evaluated;
					if (doubleNegate) evaluated = !evaluated;
					res.push(evaluated);
				}
			}

			if (res.length === 0) {
				return options.passthrough ? undefined : null;
			}

			if (res.length === 1) {
				return res[0];

			} else {
				// we must take care about concatenating values to string to prevent some unexpected behaviour
				return res.map(options.concat || concatenate).join('');
			}
		};

		return iterate(ast);
	}

	/**
	 * Evaluate filters.
	 * 
	 * @param {Array} filter Filter.
	 * @param {Object} data Collection of variables.
	 * @param {Function} [concat] Function to be used to concatenate values to string. Different for GUI and Core. Core version by default.
	 * @returns {Object} `match` (Boolean) and `report` (Array)
	 */

	static filter(orFilters, bundle, concat) {
		const orReport = [];

		if (!orFilters) {
			return { match: true, report: orReport };
		}
		if (!Array.isArray(orFilters)) {
			return { match: true, report: orReport };
		}
		if (!orFilters.length) {
			return { match: true, report: orReport };
		}

		for (let i = 0; i < orFilters.length; i++) {
			const andFilters = orFilters[i];
			let truthy = true;

			const andReport = [];
			orReport.push(andReport);

			const iterable = andFilters != null ? andFilters : [];
			for (let ii = 0; ii < iterable.length; ii++) {
				let result;
				const filter = iterable[ii];
				const context = {timezone: (filter.a && filter.a.timezone) || (filter.b && filter.b.timezone)};
				try {
					const a = IML.execute(filter.a, bundle, concat);
					const b = IML.execute(filter.b, bundle, concat);
					result = compare.call(context, a, b, filter.o);

				} catch (ex) {
					throw new IMLError(`Failed to evaluate filter '${i}-${ii}': ${ex.message}`);
				}

				andReport.push(result);
				if (!result) {
					truthy = false;
					break;
				}
			}

			if (truthy) {
				return { match: true, report: orReport };
			}
		}

		return { match: false, report: orReport };
	}

	/**
	 * Analyzes IML and returns referenced variables.
	 * 
	 * @param {Object|String} ast AST generated by `IML.parse` or `String`.
	 * @param {Boolean} [create] If referenced module doesn't exists, create one.
	 * @param {Object} [modules] Existing collection of modules.
	 * @returns {Array}
	 */

	static references(ast, create, modules) {
		if (create == null) {
			create = false;
		}
		if (modules == null) {
			modules = {};
		}
		IML.errors = [];

		if (!IML.isAST(ast)) {
			if (typeof ast !== 'string') {
				IML.errors.push(new IMLError('Invalid IML.'));
				return modules;
			}

			ast = IML.parse(ast);
		}

		const iterate = (nodes) => {
			for (const node of nodes) {
				switch (node.type) {
					case 'variable':
						if (!IML.VARIABLES[node.name]) {
							let cur;
							let variable;

							if (/^([0-9]+)\.(.*)$/.exec(node.name)) {
								const id = RegExp.$1;
								variable = RegExp.$2;

								if (!modules[id]) {
									if (!create) {
										IML.errors.push(new IMLError(`Variable '${node.name}' references not existing module '${id}'.`));
										continue;
									}

									modules[id] = {};
								}

								cur = modules[id];

							} else {
								variable = node.name;
								cur = modules;
							}

							variable = splitVariable(variable);

							while (variable.length) {
								let v = variable.shift();
								if ((/^(.*)\[\d*\]$/).exec(v)) {
									v = RegExp.$1;
									variable = []; // dont seek inside arrays
								}

								if (variable.length) {
									if (cur[v] === true) {
										cur = (cur[v] = {});

									} else {
										cur = cur[v] != null ? cur[v] : (cur[v] = {});
									}

								} else {
									cur[v] = true;
								}
							}
						}
						break;

					case 'function':
						for (const arg of node.arguments || []) {
							iterate(arg);
						}
						break;
				}
			}

			return modules;
		};

		return iterate(ast);
	}

	static outputs(ast) {
		if (!IML.isAST(ast)) {
			throw new IMLError('Invalid AST!');
		}

		if (!ast.length) {
			return { type: 'null' };
		}

		const evaluate = (node) => {
			switch (node.type) {
				case 'variable': return { type: 'variable', name: node.name };
				case 'function':
					if (!IML.FUNCTIONS[node.name]) {
						throw new IMLError(`Function '${node.name}' not found!`);
					}

					const args = (node.arguments || []).map((arg) => iterate(arg));

					let outputs = IML.FUNCTIONS[node.name].outputs;
					if (outputs) outputs = outputs.apply(null, args);
					if (!outputs) outputs = { type: IML.FUNCTIONS[node.name].type };
					return outputs;

				case 'text': case 'string': return { type: 'text' };
				case 'number': return { type: 'number' };
				case 'keyword': return { type: IML.KEYWORDS[node.name].type };
			}

			return null;
		};

		const operate = (operation, lhs) => {
			const op = operation.shift();
			let rhs = operation.shift();

			if (operation.length && (operation[0].precedence > op.precedence)) {
				rhs = operate(operation, rhs);
			}

			if (operation.length) {
				if ((op === IML.OPERATORS['+']) && ((lhs.type === 'text') || (rhs.type === 'text'))) {
					return operate(operation, { type: 'text' });

				} else {
					return operate(operation, op.type);
				}

			} else {
				if ((op === IML.OPERATORS['+']) && ((lhs.type === 'text') || (rhs.type === 'text'))) {
					return { type: 'text' };

				} else {
					return { type: op.type };
				}
			}
		};

		const iterate = (nodes) => {
			// evaluate first

			let index = 0;
			const res = [];

			while (index < nodes.length) {
				let node = nodes[index++];

				switch (node.type) {
					case 'operator':
						// operator can't stand on the begining of the line
						if (res.length) {
							const operation = [res.pop()];

							while ((node != null ? node.type : undefined) === 'operator') {
								if (!IML.OPERATORS[node.name]) {
									throw new IMLError(`Operator '${node.name}' not found!`);
								}

								operation.push(IML.OPERATORS[node.name]);
								operation.push(evaluate(nodes[index++]));
								node = nodes[index++];
							}

							res.push(operate(operation, operation.shift()));
							index--;
						}
						break;

					default:
						res.push(evaluate(node));
				}
			}

			if (res.length === 0) {
				return { type: 'null' };
			}

			if (res.length === 1) {
				return res[0];

			} else {
				return { type: 'text' };
			}
		};

		return iterate(ast);
	}

	static wrap(ast, func) {
		if (!IML.isAST(ast)) throw new IMLError('Invalid AST!');

		if (this.isWrapped(ast, func)) {
			// already wrapped into that function
			return ast;
		}

		// we are about to do some changes directly to original ast so we need to clone array first
		// so our changes are not visible to the original array.
		ast = ast.clone();

		const args = [ast];
		args.type = 'argument';

		const wrapper = ast.derive();
		wrapper.push({
			type: 'function',
			name: func,
			arguments: args
		});

		let index = 0;
		while (index < ast.length) {
			if (ast[index].type === 'text') {
				ast[index] = {
					type: 'string',
					value: ast[index].value
				};

				// inject plus operator when necessary

				if ((index === 0) && (ast.length > 1)) {
					ast.splice(index + 1, 0, {
						type: 'operator',
						name: '+'
					});

					index++;
				} else if (index > 0) {
					ast.splice(index, 0, {
						type: 'operator',
						name: '+'
					});
				}
			}

			index++;
		}

		return wrapper;
	}

	static unwrap(ast, func) {
		if (!IML.isAST(ast)) throw new IMLError('Invalid AST!');

		if (!this.isWrapped(ast, func)) {
			return ast;
		}

		ast = ast[0].arguments[0].clone();

		let index = 0;
		while (index < ast.length) {
			if (ast[index].type === 'string') {
				ast[index] = {
					type: 'text',
					value: ast[index].value
				};

				// remove unnecessary plus operators

				if ((index > 0) && (ast[index - 1].type === 'operator')) {
					ast.splice(index - 1, 1);
					index--;
				}

				if (((index + 1) < ast.length) && (ast[index + 1].type === 'operator')) {
					ast.splice(index + 1, 1);
				}
			}

			index++;
		}

		return ast;
	}

	static isWrapped(ast, func) {
		if (!IML.isAST(ast)) throw new IMLError('Invalid AST!');

		if ((ast.length === 1) && (ast[0].type === 'function') && (ast[0].name === func)) {
			return true;
		}

		return false;
	}

	static containsIML(text) {
		return text.indexOf('{{') > -1 && text.indexOf('}}') > -1;
	}

	static replace(ast, type, find, replace) {
		if (!IML.isAST(ast)) throw new IMLError('Invalid AST!');

		const list = typeof find === 'object' ? find : {[find]: replace};

		function iterate(nodes) {
			for (const node of nodes) {
				for (const find in list) {
					if (list.hasOwnProperty(find)) {
						const replace = list[find];
						switch (node.type) {
							case 'keyword':
								if (type === 'keyword' && find === node.name) {
									node.name = replace;
								}
								break;

							case 'function':
								for (const arg of node.arguments || []) iterate(arg);
								break;

							case 'variable':
								if (type === 'variable' && find === node.name) {
									if (!node.path) {
										node.name = replace;
									} else {
										const replacer = IML.parse(`{{${replace}}}`)[0];
										node.path = replacer.path;
									}
								} else if (type === 'reference' && !IML.VARIABLES[node.name] && node.name.indexOf(`${find}.`) === 0) {
									node.name = `${replace}.${node.name.substr(String(find).length + 1)}`;
								}
								break;
						}
					}
				}
			}
		}

		iterate(ast);
		return ast;
	}
}

IML.errors = [];
IML.defaults = null; // defaults are key, value collection attached to IMLArray - function values are attached as getters
IML.Array = IMLArray;
IML._escape = escape;
IML._unescape = unescape;
IML._mapVariable = mapVariable;
IML._splitVariable = splitVariable;

/**
 * Simplified Integromat Markup Language. This class is singleton.
 * 
 * @property {Array} errors Array of parse/stringify errors. (static property)
 * @singleton
 */

class SIML {
	/**
	 * Execute SIML and return computed value.
	 * 
	 * @param {String} text SIML string.
	 * @param {Object} data Collection of variables.
	 * @param {Boolean} [escape] If `true`, values are sanitized.
	 * @returns {String} Computed value.
	 */

	static execute(text, data, escape) {
		if (text == null) {
			text = '';
		}
		if (escape == null) {
			escape = false;
		}
		return String(text).replace(/{{([^}]*)}}/g, (p) => {
			let value;
			if (!data) {
				return '';
			}

			const key = p.substr(2, p.length - 4).split('.');

			if (key.length > 1) {
				let cur = data;
				while (cur && key.length) {
					cur = cur[key.shift()];
				}

				value = cur != null ? cur : '';

			} else {
				value = data[key[0]];
			}

			if (escape) {
				value = escapeHTML(value);
			}
			return value;
		});
	}

	static resolveContext(spec = [], data = {}) {
		const context = {};

		function resolveValue(value, pre, siml, post) {
			if (!(value && typeCheck(value) === 'string')) return value;
			if (post.length > 0) return value.slice(pre.length, -post.length);

			return value.slice(pre.length);
		}

		function resolveContext(text, value) {
			if ((text.match(/{{([^}]*)}}/g) || []).length > 1) throw new Error('String contains more than one SIML expression.');

			String(text).replace(/(.*?){{([^}]*)}}(.*)/, (m, pre, siml, post) => {
				// If SIML is empty or invalid e.g. {{}}, {{.data}}, {{data.}}
				if (!/^[^.]+(\.[^.]+)*$/.test(siml)) throw new Error('Invalid SIML.');

				const path = siml.split('.');
				let cur = context;

				path.forEach((k, i) => {
					if (i < path.length - 1) {
						cur = cur[k] = cur[k] || {};
					} else {
						cur[k] = resolveValue(value, pre, siml, post);
					}
				});
			});
		}

		function findSIML(spec, data) {
			Object.keys(spec).forEach((key) => {
				const sp = spec[key];
				const dt = data && data[key];

				switch (typeCheck(sp)) {
					case 'string':
						if (IML.containsIML(sp)) resolveContext(sp, dt);
						break;
					case 'object':
						findSIML(sp, dt);
				}
			});
		}

		findSIML(spec, data);

		return context;
	}
}

exports.IML = IML;
exports.SIML = SIML;
exports.IMLError = IMLError;

const {IMLTree} = require('./tree');
exports.IMLTree = IMLTree;
