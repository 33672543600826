const {IML} = require('./iml');

class IMLTree {
	/**
	 * Parses the given object to an IML Tree AST
	 * @param {object} source
	 * @return {any}
	 */
	static parse(source) {
		return JSON.parse(JSON.stringify(source), (key, value) => {
			if (key === '') {
				return value;
			} else if (typeof value === 'string') {
				return IML.parse(value);
			} else if (typeof value === 'object' && value !== null) {
				return IMLTree.parse(value);
			}
			return value;
		});
	}

	/**
	 * Executes the given IML Tree AST recursively
	 * @param {object} tree
	 * @param {object} context
	 * @param {object} [options]
	 * @return {object}
	 */
	static execute(tree, context, options) {
		const obj = {};
		if (IML.isAST(tree)) {
			return IML.execute(tree, context, options);
		}
		if (Array.isArray(tree)) {
			return tree.map(item => IMLTree.execute(item, context, options));
		}
		Object.keys(tree).forEach(key => {
			if (IML.isAST(tree[key])) {
				obj[key] = IML.execute(tree[key], context, options);
			} else if (typeof tree[key] === 'object' && tree[key] !== null) {
				obj[key] = IMLTree.execute(tree[key], context, options);
			} else {
				obj[key] = tree[key];
			}
		});
		return obj;
	}

	/**
	 * Instantly parses and executes the IML Tree
	 * @param {object} source
	 * @param {object} context
	 * @param {object} [options]
	 * @return {object}
	 */
	static evaluate(source, context, options) {
		return JSON.parse(JSON.stringify(source), (key, value) => {
			if (key === '') {
				return value;
			} else if (typeof value === 'string') {
				return IML.execute(IML.parse(value), context, options);
			} else if (typeof value === 'object' && value !== null) {
				return IMLTree.evaluate(value, context, options);
			}
			return value;
		});
	}

	/**
	 * Replaces expressions in the IML Tree
	 * @param {object} source
	 * @param {string} type
	 * @param {string|number} find
	 * @param {string|number} replace
	 * @return {object}
	 */
	static replace(source, type, find, replace) {
		return JSON.parse(JSON.stringify(source), (key, value) => {
			if (key === '') {
				return value;
			} else if (typeof value === 'string') {
				return IML.stringify(IML.replace(IML.parse(value), type, find, replace));
			} else if (typeof value === 'object' && value !== null) {
				return IMLTree.replace(value, type, find, replace);
			}
			return value;
		});
	}

	/**
	 * Resolves all references in the IML Tree
	 * @param {object} tree
	 * @param {boolean} [create] If referenced module doesn't exists, create one.
	 * @param {object} [modules] Existing collection of modules.
	 * @return {object}
	 */
	static references(tree, create, modules = {}) {
		if (IML.isAST(tree) || typeof tree === 'string' || tree instanceof String) {
			IML.references(tree, create, modules);
			return modules;
		}
		if (Array.isArray(tree)) {
			for (const item of tree) {
				IMLTree.references(item, create, modules);
			}
			return modules;
		}
		Object.keys(tree).forEach(key => {
			if (IML.isAST(tree[key]) || typeof tree[key] === 'string' || tree[key] instanceof String) {
				IML.references(tree[key], create, modules);
			} else if (typeof tree[key] === 'object' && tree[key] !== null) {
				IMLTree.references(tree[key], create, modules);
			}
		});
		return modules;
	}
}

exports.IMLTree = IMLTree;
