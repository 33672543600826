'use strict';

module.exports = {
	timestamp: {
		type: 'uinteger',
		group: 'date',
		value() {
			return Math.floor(Date.now() / 1000);
		}
	},

	pi: {
		type: 'number',
		group: 'math',
		value: Math.PI
	},

	now: {
		type: 'date',
		group: 'date',
		value() {
			return new Date();
		}
	},

	uuid: {
		type: 'text',
		group: 'string',
		value() {
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
				const r = (Math.random() * 16) | 0;
				const v = c === 'x' ? r : ((r & 0x3) | 0x8);
				return v.toString(16);
			});
		}
	},

	random: {
		type: 'number',
		group: 'math',
		value() {
			return Math.random();
		}
	}
};
