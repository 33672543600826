'use strict';

const moment = require('moment-timezone');
const crypto = require('crypto');
const {IMTDate} = require('@integromat/dateparser');
const {Buffer, mapVariable, typeCheck, deepEqual, valueError, isNumber} = require('./utils.js');
const compare = require('./compare.js');

// Just to not duplicate the code, we needed to do this because of backward compatibility
const _distinct = {
	type: 'array',
	group: 'array',
	value(array) {
		if (array == null) {
			if (this.passthrough) return array;
			return null;
		}

		if (typeCheck(array) !== 'array') throw new Error(valueError(array, "'{{value}}' is not a valid array.", 'Invalid array.'));

		const key = arguments[1];
		const values = [];
		return array.filter((value) => {
			if (key) {
				value = mapVariable.call(this, value, key);
			}

			if ((value == null)) {
				if (-1 !== values.indexOf(null)) {
					return false;
				}
			}

			if (['string', 'number', 'boolean'].includes(typeof(value))) {
				value = String(value);

				if (-1 !== values.indexOf(value)) {
					return false;
				}
			} else if (typeCheck(value) === 'date') {
				value = value.getTime();

				if (-1 !== values.indexOf(value)) {
					return false;
				}
			} else if ((value != null) && (typeof(value) === 'object')) {
				let found = false;
				for (const val of values) {
					if ((val != null) && (typeof(val) === 'object') && deepEqual(value, val)) {
						found = true;
						break;
					}
				}

				if (found) {
					return false;
				}
			} else if (value != null) {
				// unknown object, remove
				return false;
			}

			values.push(value != null ? value : null);
			return true;
		});
	},

	outputs(array) {
		return array;
	}
}

const FUNCTIONS = module.exports = {
	'': {
		type: '*',
		group: 'general',
		value(value) {
			return value;
		},

		outputs(value) {
			return value;
		}
	},

	'get': {
		type: '*',
		group: 'general',
		value(value, property) {
			return mapVariable.call(this, value, property);
		}
	},

	'average': {
		type: 'number',
		group: 'math',
		value(one) {
			let arr;
			if (typeCheck(one) === 'array') {
				arr = one;
			} else {
				arr = Array.prototype.slice.call(arguments);
			}

			const numArr = arr.filter(num => isNumber(num));

			let sum = 0;
			for (const num of numArr) {
				sum += num;
			}
			return sum / numArr.length;
		}
	},

	'ceil': {
		type: 'integer',
		group: 'math',
		value(num) {
			if (num == null) {
				if (this.passthrough) return num;
				return null;
			}

			if (num === '') return null;

			if (typeCheck(num) !== 'number') {
				throw new Error(valueError(num, "'{{value}}' is not a valid number.", 'Invalid number.'));
			}

			return Math.ceil(num);
		}
	},

	'floor': {
		type: 'integer',
		group: 'math',
		value(num) {
			if (num == null) {
				if (this.passthrough) return num;
				return null;
			}

			if (num === '') return null;

			if (typeCheck(num) !== 'number') {
				throw new Error(valueError(num, "'{{value}}' is not a valid number.", 'Invalid number.'));
			}

			return Math.floor(num);
		}
	},

	'if': {
		type: '*',
		group: 'general',
		value(statement, exp1, exp2) {
			if (statement) {
				return exp1;
			} else {
				return exp2;
			}
		},

		outputs(statement, exp1, exp2) {
			return exp1;
		}
	},

	'ifempty': {
		type: '*',
		group: 'general',
		value(exp1, exp2) {
			if (typeCheck(exp1) === 'string') {
				if (exp1.length === 0) {
					return exp2;
				} else {
					return exp1;
				}
			}

			return exp1 != null ? exp1 : exp2;
		},

		outputs(exp1) {
			return exp1;
		}
	},

	'switch': {
		type: '*',
		group: 'general',
		value(value, k, v) {
			let def;
			const args = Array.prototype.slice.call(arguments);
			if (args.length % 2 === 0) def = args.pop();

			const type = typeCheck(value);
			if (type !== 'string' && type !== 'number' && type !== 'boolean') {
				if (def != null || this.passthrough) return def;
				return null;
			}

			value = String(value);
			let i = 1;
			while (i < args.length) {
				const type = typeCheck(args[i]);
				if (type === 'string' || type === 'number' || type === 'boolean') {
					if (value === String(args[i])) return args[i + 1];
				}

				i += 2;
			}

			if (def != null || this.passthrough) return def;
			return null;
		}
	},

	'join': {
		type: 'text',
		group: 'array',
		value(array, separator) {
			const join = (array, separator, brackets) => {
				if (separator == null) {
					separator = ',';
				}
				if (brackets == null) {
					brackets = false;
				}
				let out = '';
				if (brackets) {
					out += '[';
				}

				for (let i = 0; i < array.length; i++) {
					const elem = array[i];
					switch (typeCheck(elem)) {
						case 'null': out += ''; break;
						case 'undefined': out += ''; break;
						case 'object': out += '{object}'; break;
						case 'buffer': out += '{buffer}'; break;
						case 'date': out += elem.toISOString(); break;
						case 'array': out += join(elem, separator, brackets); break;
						default: out += elem;
					}

					if (i < (array.length - 1)) {
						out += separator;
					}
				}

				if (brackets) {
					out += ']';
				}
				return out;
			};

			if (typeCheck(array) !== 'array') {
				return '';
			}

			if (separator == null || !['string', 'number'].includes(typeCheck(separator))) {
				return join(array, ',', (arguments[2] != null) && (arguments[2] === true));
			}

			return join(array, separator, (arguments[2] != null) && (arguments[2] === true));
		}
	},

	'length': {
		type: 'uinteger',
		group: ['string', 'array'],
		value(text) {
			if (text == null) return 0;

			switch (typeCheck(text)) {
				case 'array': case 'buffer': return text.length;
				default: return String(text).length;
			}
		}
	},

	'lower': {
		type: 'text',
		group: 'string',
		value(text) {
			if (text == null) {
				if (this.passthrough) return text;
				return null;
			}

			return String(text).toLowerCase();
		}
	},

	'capitalize': {
		type: 'text',
		group: 'string',
		value(text) {
			if (text == null) {
				if (this.passthrough) return text;
				return null;
			}

			text = String(text);

			return text.charAt(0).toUpperCase() + text.slice(1);
		}
	},

	'startcase': {
		type: 'text',
		group: 'string',
		value(text) {
			if (text == null) {
				if (this.passthrough) return text;
				return null;
			}

			text = String(text);

			return text
				.split(' ')
				.map(word => FUNCTIONS.capitalize.value(word.toLowerCase()))
				.join(' ');
		}
	},

	'ascii': {
		type: 'text',
		group: 'string',
		value(text) {
			if (text == null) {
				if (this.passthrough) return text;
				return null;
			}

			text = String(text);

			if (arguments[1]) {
				try {
					text = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
				} catch (ex) {}
			}
			// ignore error

			return text.replace(/[^\x00-\x7F]/g, '');
		}
	},

	'max': {
		type: 'number',
		group: 'math',
		value(one) {
			let arr;
			if (typeCheck(one) === 'array') {
				arr = one;
			} else {
				arr = Array.prototype.slice.call(arguments);
			}

			const numArr = arr.filter(num => isNumber(num));

			return Math.max.apply(null, numArr);
		}
	},

	'min': {
		type: 'number',
		group: 'math',
		value(one) {
			let arr;
			if (typeCheck(one) === 'array') {
				arr = one;
			} else {
				arr = Array.prototype.slice.call(arguments);
			}

			const numArr = arr.filter(num => isNumber(num));

			return Math.min.apply(null, numArr);
		}
	},

	'replace': {
		type: 'text',
		group: 'string',
		value(text, what, replace) {
			if (text == null) {
				if (this.passthrough) return text;
				return null;
			}

			if (what == null) return text;
			if ((replace == null)) return text;

			if ((typeCheck(text) === 'buffer') && ['ascii', 'utf8'].includes(text.codepage)) {
				text = text.toString(text.codepage);
			}

			if (!['string', 'number'].includes(typeCheck(text))) {
				throw new Error(valueError(text, "'{{value}}' is not string or number.", 'Invalid string or number.'));
			}
			if (!['string', 'number'].includes(typeCheck(what))) {
				throw new Error(valueError(what, "'{{value}}' is not string or number.", 'Invalid string or number.'));
			}
			if (!['string', 'number'].includes(typeCheck(replace))) {
				throw new Error(valueError(replace, "'{{value}}' is not string or number.", 'Invalid string or number.'));
			}

			let regExp;
			if (/^\/.*\/[gim]?$/.test(what)) {
				const match = /^\/(.*)\/([gim]?)$/.exec(what);
				regExp = new RegExp(match[1], match[2]);
			} else {
				regExp = new RegExp(String(what).replace(/[^\w\s]/gi, '\\$&'), 'g');
			}

			return String(text).replace(regExp, replace);
		}
	},

	'round': {
		type: 'integer',
		group: 'math',
		value(num) {
			if (num == null) {
				if (this.passthrough) return num;
				return null;
			}

			if (num === '') return null;

			if (!['string', 'number'].includes(typeCheck(num))) {
				throw new Error(valueError(num, "'{{value}}' is not a valid number.", 'Invalid number.'));
			}

			return Math.round(num);
		}
	},

	'trim': {
		type: 'text',
		group: 'string',
		value(text) {
			if (text == null) {
				if (this.passthrough) return text;
				return null;
			}

			return String(text).trim();
		}
	},

	'upper': {
		type: 'text',
		group: 'string',
		value(text) {
			if (text == null) {
				if (this.passthrough) return text;
				return null;
			}

			return String(text).toUpperCase();
		}
	},

	'substring': {
		type: 'text',
		group: 'string',
		value(text, start, end) {
			if (text == null) {
				if (this.passthrough) return text;
				return null;
			}

			return String(text).substring(start, end);
		}
	},

	'indexOf': {
		type: 'integer',
		group: 'string',
		value(text, value) {
			if (text == null) {
				if (this.passthrough) return text;
				return null;
			}

			return String(text).indexOf(value, arguments[2]);
		}
	},

	'sum': {
		type: 'number',
		group: 'math',
		value(one) {
			let arr;
			if (typeCheck(one) === 'array') {
				arr = one;
			} else {
				arr = Array.prototype.slice.call(arguments);
			}

			let sum = 0;
			for (const num of arr) {
				if (isNumber(num)) {
					sum += parseFloat(num);
				}
			}
			return sum;
		}
	},

	'toBinary': {
		type: 'text',
		group: 'string',
		value(entry) {
			if (entry == null) {
				if (this.passthrough) return entry;
				return null;
			}

			if (typeCheck(entry) === 'buffer') {
				return entry;
			}

			let encoding = 'utf8';
			if (arguments.length === 2) {
				encoding = arguments[1];
			}

			if (typeCheck(encoding) !== 'string') {
				throw new Error(valueError(encoding, "'{{value}}' is not a valid encoding.", 'Invalid encoding.'));
			}

			encoding = encoding.toLowerCase();
			if (encoding === 'utf-8') {
				encoding = 'utf8';
			}
			if (!['ascii', 'hex', 'base64', 'utf8', 'binary'].includes(encoding)) {
				throw new Error(valueError(encoding, "'{{value}}' is not a valid encoding.", 'Invalid encoding.'));
			}

			if (typeCheck(entry) === 'string') {
				return Buffer.from(entry, encoding);

			} else {
				return Buffer.from(FUNCTIONS.toString.value(entry));
			}
		}
	},

	'toString': {
		type: 'text',
		group: 'string',
		value(entry) {
			if (entry == null) return '';

			let encoding = 'utf8';
			if (arguments.length === 2) {
				encoding = arguments[1];
			}

			if (typeCheck(encoding) !== 'string') {
				throw new Error(valueError(encoding, "'{{value}}' is not a valid encoding.", 'Invalid encoding.'));
			}

			encoding = encoding.toLowerCase();
			if (encoding === 'utf-8') {
				encoding = 'utf8';
			}
			if (!['ascii', 'hex', 'base64', 'utf8', 'binary'].includes(encoding)) {
				throw new Error(valueError(encoding, "'{{value}}' is not a valid encoding.", 'Invalid encoding.'));
			}

			switch (typeCheck(entry)) {
				case 'number':
					return entry.toString();
				case 'array':
					return FUNCTIONS.join.value(entry, ',', true);
				case 'date':
					return entry.toISOString();
				case 'buffer':
					return entry.toString(encoding);
				case 'object':
					return '{object}';
				case 'null':
				case 'undefined':
				case 'unknown':
					return '';
				default:
					return String(entry);
			}
		}
	},

	'encodeURL': {
		type: 'text',
		group: 'string',
		value(text) {
			if (text == null) {
				if (this.passthrough) return text;
				return null;
			}

			return encodeURIComponent(text);
		}
	},

	'decodeURL': {
		type: 'text',
		group: 'string',
		value(text) {
			if (text == null) {
				if (this.passthrough) return text;
				return null;
			}

			return decodeURIComponent(text);
		}
	},

	'escapeHTML': {
		type: 'text',
		group: 'string',
		value(text) {
			if (text == null) {
				if (this.passthrough) return text;
				return null;
			}

			const map = {'&': '&amp;', '<': '&lt;', '>': '&gt;'};
			return String(text).replace(/[&<>]/g, tag => map[tag] || tag);
		}
	},

	'escapeMarkdown': {
		type: 'text',
		group: 'string',
		value(text) {
			if (text == null) {
				if (this.passthrough) return text;
				return null;
			}

			const map = {
				'#': '&#35;',
				'*': '&#42;',
				'_': '&#95;',
				'=': '&#61;',
				'-': '&#45;',
				'~': '&#126;',
				'+': '&#43;',
				'[': '&#91;',
				']': '&#93;',
				'(': '&#40;',
				')': '&#41;',
				':': '&#58;',
				'!': '&#33;',
				'/': '&#47;',
				'\\': '&#92;',
				'`': '&#96;',
				'|': '&#124;',
				'>': '&gt;',
				'<': '&lt;',
				'&': '&amp;'
			};
			return String(text).replace(/[\#\*\_\=\-\~\+\[\]\(\)\:\!\/\\\`\|<>&]/g, tag => map[tag] || tag);
		}
	},

	'stripHTML': {
		type: 'text',
		group: 'string',
		value(text) {
			if (text == null) {
				if (this.passthrough) return text;
				return null;
			}

			return String(text).replace(/<style[^>]*>[\s\S]*?<\/style>/g, '')
			.replace(/<script[^>]*>[\s\S]*?<\/script>/g, '')
			.replace(/<br\s*\/?>/g, '\n')
			.replace(/<\/?[^>]+>/g, '');
		}
	},

	'addSeconds': {
		type: 'date',
		group: 'date',
		value(date, numberOfSeconds) {
			if (date == null) {
				if (this.passthrough) return date;
				return null;
			}

			let dateObj;
			switch (typeCheck(date)) {
				case 'date':
					dateObj = new Date(+date);
					break;
				case 'number':
					dateObj = new Date(Number(date));
					break;
				case 'string':
					dateObj = IMTDate.parse(date, this.timezone);
					break;
				default:
					throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			if (!dateObj || isNaN(dateObj.getTime())) {
				throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			const seconds = Number(numberOfSeconds);
			if (!isNumber(seconds)) {
				throw new Error(valueError(numberOfSeconds, "'{{value}}' is not a valid number of seconds.", 'Invalid number of seconds.'));
			}

			return moment(dateObj).tz(this.timezone).add(seconds, 's').toDate();
		}
	},

	'addMinutes': {
		type: 'date',
		group: 'date',
		value(date, numberOfMinutes) {
			if (date == null) {
				if (this.passthrough) return date;
				return null;
			}

			let dateObj;
			switch (typeCheck(date)) {
				case 'date':
					dateObj = new Date(+date);
					break;
				case 'number':
					dateObj = new Date(Number(date));
					break;
				case 'string':
					dateObj = IMTDate.parse(date, this.timezone);
					break;
				default:
					throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			if (!dateObj || isNaN(dateObj.getTime())) {
				throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			const minutes = Number(numberOfMinutes);
			if (!isNumber(minutes)) {
				throw new Error(valueError(numberOfMinutes, "'{{value}}' is not a valid number of minutes.", 'Invalid number of minutes.'));
			}

			return moment(dateObj).tz(this.timezone).add(minutes, 'm').toDate();
		}
	},

	'addHours': {
		type: 'date',
		group: 'date',
		value(date, numberOfHours) {
			if (date == null) {
				if (this.passthrough) return date;
				return null;
			}

			let dateObj;
			switch (typeCheck(date)) {
				case 'date':
					dateObj = new Date(+date);
					break;
				case 'number':
					dateObj = new Date(Number(date));
					break;
				case 'string':
					dateObj = IMTDate.parse(date, this.timezone);
					break;
				default:
					throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			if (!dateObj || isNaN(dateObj.getTime())) {
				throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			const hours = Number(numberOfHours);
			if (!isNumber(hours)) {
				throw new Error(valueError(numberOfHours, "'{{value}}' is not a valid number of hours.", 'Invalid number of hours.'));
			}

			return moment(dateObj).tz(this.timezone).add(hours, 'H').toDate();
		}
	},

	'addDays': {
		type: 'date',
		group: 'date',
		value(date, numberOfDays) {
			if (date == null) {
				if (this.passthrough) return date;
				return null;
			}

			let dateObj;
			switch (typeCheck(date)) {
				case 'date':
					dateObj = new Date(+date);
					break;
				case 'number':
					dateObj = new Date(Number(date));
					break;
				case 'string':
					dateObj = IMTDate.parse(date, this.timezone);
					break;
				default:
					throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			if (!dateObj || isNaN(dateObj.getTime())) {
				throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			const days = Number(numberOfDays);
			if (!isNumber(days)) {
				throw new Error(valueError(numberOfDays, "'{{value}}' is not a valid number of days.", 'Invalid number of days.'));
			}

			return moment(dateObj).tz(this.timezone).add(days, 'd').toDate();
		}
	},

	'addMonths': {
		type: 'date',
		group: 'date',
		value(date, numberOfMonths) {
			if (date == null) {
				if (this.passthrough) return date;
				return null;
			}

			let dateObj;
			switch (typeCheck(date)) {
				case 'date':
					dateObj = new Date(+date);
					break;
				case 'number':
					dateObj = new Date(Number(date));
					break;
				case 'string':
					dateObj = IMTDate.parse(date, this.timezone);
					break;
				default:
					throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			if (!dateObj || isNaN(dateObj.getTime())) {
				throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			const months = Number(numberOfMonths);
			if (!isNumber(months)) {
				throw new Error(valueError(numberOfMonths, "'{{value}}' is not a valid number of months.", 'Invalid number of months.'));
			}

			return moment(dateObj).tz(this.timezone).add(months, 'M').toDate();
		}
	},

	'addYears': {
		type: 'date',
		group: 'date',
		value(date, numberOfYears) {
			if (date == null) {
				if (this.passthrough) return date;
				return null;
			}

			let dateObj;
			switch (typeCheck(date)) {
				case 'date':
					dateObj = new Date(+date);
					break;
				case 'number':
					dateObj = new Date(Number(date));
					break;
				case 'string':
					dateObj = IMTDate.parse(date, this.timezone);
					break;
				default:
					throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			if (!dateObj || isNaN(dateObj.getTime())) {
				throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			const years = Number(numberOfYears);
			if (!isNumber(years)) {
				throw new Error(valueError(numberOfYears, "'{{value}}' is not a valid number of years.", 'Invalid number of years.'));
			}

			return moment(dateObj).tz(this.timezone).add(years, 'Y').toDate();
		}
	},

	'setSecond': {
		type: 'date',
		group: 'date',
		value(date, numberOfSeconds) {
			if (date == null) {
				if (this.passthrough) return date;
				return null;
			}

			let dateObj;
			switch (typeCheck(date)) {
				case 'date':
					dateObj = new Date(+date);
					break;
				case 'number':
					dateObj = new Date(Number(date));
					break;
				case 'string':
					dateObj = IMTDate.parse(date, this.timezone);
					break;
				default:
					throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			if (!dateObj || isNaN(dateObj.getTime())) {
				throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			const seconds = Number(numberOfSeconds);
			if (!isNumber(seconds)) {
				throw new Error(valueError(numberOfSeconds, "'{{value}}' is not a valid number of seconds.", 'Invalid number of seconds.'));
			}

			return moment(dateObj).tz(this.timezone).second(seconds).toDate();
		}
	},

	'setMinute': {
		type: 'date',
		group: 'date',
		value(date, numberOfMinutes) {
			if (date == null) {
				if (this.passthrough) return date;
				return null;
			}

			let dateObj;
			switch (typeCheck(date)) {
				case 'date':
					dateObj = new Date(+date);
					break;
				case 'number':
					dateObj = new Date(Number(date));
					break;
				case 'string':
					dateObj = IMTDate.parse(date, this.timezone);
					break;
				default:
					throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			if (!dateObj || isNaN(dateObj.getTime())) {
				throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			const minutes = Number(numberOfMinutes);
			if (!isNumber(minutes)) {
				throw new Error(valueError(numberOfMinutes, "'{{value}}' is not a valid number of minutes.", 'Invalid number of minutes.'));
			}

			return moment(dateObj).tz(this.timezone).minute(minutes).toDate();
		}
	},

	'setHour': {
		type: 'date',
		group: 'date',
		value(date, numberOfHours) {
			if (date == null) {
				if (this.passthrough) return date;
				return null;
			}

			let dateObj;
			switch (typeCheck(date)) {
				case 'date':
					dateObj = new Date(+date);
					break;
				case 'number':
					dateObj = new Date(Number(date));
					break;
				case 'string':
					dateObj = IMTDate.parse(date, this.timezone);
					break;
				default:
					throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			if (!dateObj || isNaN(dateObj.getTime())) {
				throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			const hours = Number(numberOfHours);
			if (!isNumber(hours)) {
				throw new Error(valueError(numberOfHours, "'{{value}}' is not a valid number of hours.", 'Invalid number of hours.'));
			}

			return moment(dateObj).tz(this.timezone).hours(hours).toDate();
		}
	},

	'setDay': {
		type: 'date',
		group: 'date',
		value(date, numberOfDays) {
			if (date == null) {
				if (this.passthrough) return date;
				return null;
			}

			let dateObj;
			switch (typeCheck(date)) {
				case 'date':
					dateObj = new Date(+date);
					break;
				case 'number':
					dateObj = new Date(Number(date));
					break;
				case 'string':
					dateObj = IMTDate.parse(date, this.timezone);
					break;
				default:
					throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			if (!dateObj || isNaN(dateObj.getTime())) {
				throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			if (/^(monday|mon|tuesday|tue|wednesday|wed|thursday|thu|friday|fri|saturday|sat|sunday|sun)$/i.test(numberOfDays)) {
				return moment(dateObj).tz(this.timezone).day(numberOfDays).toDate();
			}

			const days = Number(numberOfDays);
			if (!isNumber(days)) {
				throw new Error(valueError(numberOfDays, "'{{value}}' is not a valid number of days.", 'Invalid number of days.'));
			}

			return moment(dateObj).tz(this.timezone).day(days - 1).toDate();
		}
	},

	'setDate': {
		type: 'date',
		group: 'date',
		value(date, numberOfDays) {
			if (date == null) {
				if (this.passthrough) return date;
				return null;
			}

			let dateObj;
			switch (typeCheck(date)) {
				case 'date':
					dateObj = new Date(+date);
					break;
				case 'number':
					dateObj = new Date(Number(date));
					break;
				case 'string':
					dateObj = IMTDate.parse(date, this.timezone);
					break;
				default:
					throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			if (!dateObj || isNaN(dateObj.getTime())) {
				throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			const days = Number(numberOfDays);
			if (!isNumber(days)) {
				throw new Error(valueError(numberOfDays, "'{{value}}' is not a valid number of days.", 'Invalid number of days.'));
			}

			return moment(dateObj).tz(this.timezone).date(days).toDate();
		}
	},

	'setMonth': {
		type: 'date',
		group: 'date',
		value(date, numberOfMonths) {
			if (date == null) {
				if (this.passthrough) return date;
				return null;
			}

			let dateObj;
			switch (typeCheck(date)) {
				case 'date':
					dateObj = new Date(+date);
					break;
				case 'number':
					dateObj = new Date(Number(date));
					break;
				case 'string':
					dateObj = IMTDate.parse(date, this.timezone);
					break;
				default:
					throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			if (!dateObj || isNaN(dateObj.getTime())) {
				throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			// eslint-disable-next-line max-len
			if (/^(january|jan|february|feb|march|mar|april|apr|may|june|jun|july|jul|august|aug|september|sep|october|oct|november|nov|december|dec)$/i.test(numberOfMonths)) {
				return moment(dateObj).tz(this.timezone).month(numberOfMonths).toDate();
			}

			const months = Number(numberOfMonths);
			if (!isNumber(months)) {
				throw new Error(valueError(numberOfMonths, "'{{value}}' is not a valid number of months.", 'Invalid number of months.'));
			}

			return moment(dateObj).tz(this.timezone).month(months - 1).toDate();
		}
	},

	'setYear': {
		type: 'date',
		group: 'date',
		value(date, numberOfYears) {
			if (date == null) {
				if (this.passthrough) return date;
				return null;
			}

			let dateObj;
			switch (typeCheck(date)) {
				case 'date':
					dateObj = new Date(+date);
					break;
				case 'number':
					dateObj = new Date(Number(date));
					break;
				case 'string':
					dateObj = IMTDate.parse(date, this.timezone);
					break;
				default:
					throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			if (!dateObj || isNaN(dateObj.getTime())) {
				throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			const years = Number(numberOfYears);
			if (!isNumber(years)) {
				throw new Error(valueError(numberOfYears, "'{{value}}' is not a valid number of years.", 'Invalid number of years.'));
			}

			return moment(dateObj).tz(this.timezone).year(years).toDate();
		}
	},

	'formatDate': {
		type: 'text',
		group: 'date',
		value(date, format) {
			if (date == null) {
				if (this.passthrough) return date;
				return null;
			}

			if (!format) format = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

			let dateObj;
			switch (typeCheck(date)) {
				case 'date':
					dateObj = date;
					break;
				case 'number':
					dateObj = new Date(Number(date));
					break;
				case 'string':
					dateObj = IMTDate.parse(date, this.timezone);
					break;
				default:
					throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			if (!dateObj || isNaN(dateObj.getTime())) {
				throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			if (typeCheck(format) !== 'string') {
				throw new Error(valueError(format, "'{{value}}' is not a valid date format.", 'Invalid date format.'));
			}

			date = moment(dateObj);

			if (arguments[2]) {
				if (typeCheck(arguments[2]) !== 'string') {
					throw new Error(valueError(arguments[2], "'{{value}}' is not a valid time zone.", 'Invalid time zone.'));
				}

				date = date.tz(arguments[2]);

			} else if (this.timezone) {
				date = date.tz(this.timezone);

			} else {
				date = date.utc();
			}

			return date.format(String(format).trim());
		}
	},

	'parseDate': {
		type: 'date',
		group: 'date',
		value(date, format) {
			if (date == null) {
				if (this.passthrough) return date;
				return null;
			}

			let mdate;
			switch (typeCheck(date)) {
				case 'date':
					return date;
					break;
				case 'number':
					if (typeCheck(format) !== 'string') {
						if (format != null) {
							throw new Error(valueError(format, "'{{value}}' is not a valid date format.", 'Invalid date format.'));
						} else {
							return new Date(date * 1000);
						}

					} else if (format === 'X') {
						return new Date(date * 1000);

					} else if (format === 'x') {
						return new Date(date);
					}

					date = String(date);
					break;

				case 'string':
					if (typeCheck(format) !== 'string') {
						if (format != null) {
							throw new Error(valueError(format, "'{{value}}' is not a valid date format.", 'Invalid date format.'));
						} else {
							return IMTDate.parse(date, this.timezone);
						}
					}
					break;
				default:
					throw new Error(valueError(date, "'{{value}}' is not a valid date.", 'Invalid date.'));
			}

			if (arguments[2]) {
				if (typeCheck(arguments[2]) === 'number') {
					mdate = moment.utc(date, format, 'en');
					mdate.add(-parseFloat(arguments[2]), 'hours');

				} else if (typeCheck(arguments[2]) === 'string') {
					mdate = moment.tz(date, format, 'en', arguments[2]);

				} else {
					throw new Error(valueError(arguments[2], "'{{value}}' is not a valid time zone.", 'Invalid time zone.'));
				}

			} else if (this.timezone) {
				mdate = moment.tz(date, format, 'en', this.timezone);

			} else {
				mdate = moment.utc(date, format, 'en');
			}

			if (!mdate.isValid()) {
				throw new Error(valueError(date, "'{{value}}' is not a valid date or does not match the date format.", 'Invalid date.'));
			}

			return mdate.toDate();
		}
	},

	'parseNumber': {
		type: 'number',
		group: 'math',
		value(stringNumber, separator) {
			if (separator == null) separator = '.';

			if (stringNumber == null) {
				if (this.passthrough) return stringNumber;
				return null;
			}

			if (typeCheck(stringNumber) === 'number') {
				return Number(stringNumber);
			}

			if (typeCheck(stringNumber) !== 'string') {
				throw new Error(valueError(stringNumber, "'{{value}}' is not a valid number.", 'Invalid number.'));
			}

			if ((typeCheck(separator) !== 'string') || ![',', '.'].includes(separator)) {
				throw new Error("Valid separators are ',' and '.'.");
			}

			separator = separator.trim();
			const sign = stringNumber.substring(0, 1).replace(/[^0-9\-]/g, '');
			let number = stringNumber.substring(1).replace(/[^0-9,\.e]/g, '');
			stringNumber = sign + number;

			if (separator === ',') {
				number = Number(stringNumber.replace(/\./g, '').replace(/,/g, '.'));
			} else {
				number = Number(stringNumber.replace(/,/g, ''));
			}

			if (!isNumber(number)) {
				throw new Error(valueError(stringNumber, "'{{value}}' is not a valid number or using unsuitable separator.", 'Invalid number or separator.'));
			}

			return number;
		}
	},

	'formatNumber': {
		type: 'text',
		group: 'math',
		value(number, decimalPoints, decimalSeparator, thousandsSeparator) {
			if (decimalSeparator == null) decimalSeparator = ',';
			if (thousandsSeparator == null) thousandsSeparator = '.';

			if (number == null) {
				if (this.passthrough) return number;
				return null;
			}

			if (!['number', 'string'].includes(typeCheck(number))) {
				throw new Error(valueError(number, "'{{value}}' is not a valid number.", 'Invalid number.'));
			}

			number = Number(number);

			if (!isNumber(number)) {
				throw new Error(valueError(number, "'{{value}}' is not a valid number or using unsuitable separator.", 'Invalid number or separator.'));
			}

			number = number.toFixed(decimalPoints);
			// https://stackoverflow.com/a/2901298/1832587 solution
			const parts = number.split('.');
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);

			return parts.join(decimalSeparator);
		}
	},

	'keys': {
		type: 'array',
		group: 'array',
		value(object) {
			if (!['object', 'array'].includes(typeCheck(object))) {
				return [];
			}

			return Object.keys(object);
		},

		outputs(array) {
			return {
				type: 'array',
				spec: { type: 'text'
				}
			};
		}
	},

	'slice': {
		type: 'array',
		group: 'array',
		value(array, begin) {
			if (array == null) {
				if (this.passthrough) return array;
				return null;
			}

			const end = arguments[2] != null ? arguments[2] : undefined;

			if (typeCheck(array) !== 'array') {
				throw new Error(valueError(array, "'{{value}}' is not a valid array.", 'Invalid array.'));
			}

			if (!isNumber(begin)) {
				throw new Error(valueError(begin, "'{{value}}' is not a valid start number.", 'Invalid start number.'));
			}

			if ((end != null) && !isNumber(end)) {
				throw new Error(valueError(end, "'{{value}}' is not a valid end number.", 'Invalid end number.'));
			}

			return array.slice(begin, end);
		},

		outputs(array) {
			return array;
		}
	},

	'merge': {
		type: 'array',
		group: 'array',
		value(array1, array2) {
			let arrays = Array.prototype.slice.call(arguments);
			arrays = arrays.filter(arr => Array.isArray(arr));
			if (!arrays.length) {
				return null;
			}
			return Array.prototype.concat.apply([], arrays || []);
		},

		outputs(array) {
			return array;
		}
	},

	'contains': {
		type: 'boolean',
		group: ['array', 'string'],
		value(array, value) {
			if (array == null || value == null) return false;

			const ci = Boolean(arguments[2]);

			if (['string', 'number'].includes(typeCheck(array))) {
				if (ci) {
					array = String(array).toLowerCase();
					value = String(value).toLowerCase();
				}

				return array.indexOf(value) >= 0;
			}

			if (typeCheck(array) !== 'array') {
				return false;
			}

			return compare.call(this, array, value, `array:contain${ci ? ':ci' : ''}`);
		}
	},

	'split': {
		type: 'array',
		group: 'string',
		value(text, delimiter) {
			if (delimiter == null) delimiter = ',';

			let arr = null;
			const keepEmpty = Boolean(arguments[2]);

			switch (typeCheck(text)) {
				case 'string':
					arr = text.split(delimiter);
					break;
				case 'number':
					if ('string' === typeof text) {
						arr = text.split(delimiter);
					} else {
						arr = [String(text)];
					}
					break;
				case 'date':
					arr = text.toISOString();
					break;
				default:
					return null;
			}

			arr = arr.map(item => item.replace(/^\s+|\s+$/g, ''));

			if (!keepEmpty) {
				arr = arr.filter(item => item !== '');
			}

			return arr;
		},

		outputs() {
			return {type: 'text'};
		}
	},

	'remove': {
		type: 'array',
		group: 'array',
		value(array, ...values) {
			if (array == null) {
				if (this.passthrough) return array;
				return null;
			}

			if (typeCheck(array) !== 'array') {
				throw new Error(valueError(array, "'{{value}}' is not a valid array.", 'Invalid array.'));
			}

			// remove everything except text and number and boolean and null
			values = values.filter((value) => {
				const type = typeCheck(value);
				return type === 'string' || type === 'number' || type === 'boolean' || type === 'null';
			});

			return array.filter((item) => {
				const type = typeCheck(item);
				if (type !== 'string' && type !== 'number' && type !== 'boolean' && type !== 'null' && type !== 'undefined') {
					return true;
				}

				return !values.some(value => {
					if (value === null) return item == null;
					if (item == null) return false;
					return String(value) === String(item);
				});
			});
		},

		outputs(array) {
			return array;
		}
	},

	'add': {
		type: 'array',
		group: 'array',
		value(array, ...values) {
			if (array == null) {
				if (this.passthrough) return array;
				return null;
			}

			if (typeCheck(array) !== 'array') {
				throw new Error(valueError(array, "'{{value}}' is not a valid array.", 'Invalid array.'));
			}

			array = array.slice(); // clone the array
			array.push.apply(array, values || []);
			return array;
		},

		outputs(array) {
			return array;
		}
	},

	'map': {
		type: 'array',
		group: 'array',
		value(array, value) {
			if (array == null) {
				if (this.passthrough) return array;
				return null;
			}

			let key; let keyNames;
			if (!Array.isArray(array)) throw new Error(valueError(array, "'{{value}}' is not a valid array.", 'Invalid array.'));
			if (!['string', 'number'].includes(typeCheck(value))) throw new Error(valueError(value, "'{{value}}' is not a valid key.", 'Invalid key.'));
			if (array.length === 0) return [];

			if (arguments[2] != null) key = arguments[2];
			if (arguments[3] != null) keyNames = arguments[3];

			if (key != null) {
				if (!['string', 'number'].includes(typeCheck(key))) {
					throw new Error(valueError(key, "'{{value}}' is not a valid key for filtering.", 'Invalid key for filtering.'));
				}
			}

			if (keyNames != null) {
				if (!['string', 'number', 'boolean'].includes(typeCheck(keyNames))) {
					throw new Error(valueError(keyNames, "'{{value}}' is not a valid list of key names for filtering.", 'Invalid key names for filtering.'));
				}

				keyNames = String(keyNames);
			}

			if ((key != null) && (keyNames != null)) {
				keyNames = keyNames.split(/\s*,\s*/);
				array = array.filter(o => {
					const val = mapVariable.call(this, o, key) || o[key];
					if (typeof val === 'undefined') return false;

					return keyNames.includes(String(val));
				});
			}

			array = array.map(o => mapVariable.call(this, o, value) || o[value]);
			return array;
		},

		outputs(array, value) {
			let spec = undefined;
			if (array && Array.isArray(array.spec)) {
				spec = array.spec.find(item => item.name === value);
			}

			return {
				type: 'array',
				spec
			};
		}
	},

	'shuffle': {
		type: 'array',
		group: 'array',
		value(array) {
			if (array == null) {
				if (this.passthrough) return array;
				return null;
			}

			if (typeCheck(array) !== 'array') throw new Error(valueError(array, "'{{value}}' is not a valid array.", 'Invalid array.'));

			array = array.slice();
			for (let i = array.length - 1; i > 0; i--) {
				const j = Math.floor(Math.random() * (i + 1));
				const x = array[i];
				array[i] = array[j];
				array[j] = x;
			}
			return array;
		},

		outputs(array) {
			return array;
		}
	},

	'sort': {
		type: 'array',
		group: 'array',
		value(array) {
			if (array == null) {
				if (this.passthrough) return array;
				return null;
			}

			if (typeCheck(array) !== 'array') throw new Error(valueError(array, "'{{value}}' is not a valid array.", 'Invalid array.'));

			let key; let order; let ci;

			if (['asc', 'desc', 'asc ci', 'desc ci'].includes(arguments[1])) {
				order = arguments[1] === 'asc' || arguments[1] === 'asc ci' ? 'asc' : 'desc';
				key = arguments[2];
				ci = arguments[1] === 'asc ci' || arguments[1] === 'desc ci';
			} else {
				order = 'asc';
				key = arguments[1];
			}

			return array.slice().sort((a, b) => {
				if (key) a = mapVariable.call(this, a, key);
				if (key) b = mapVariable.call(this, b, key);

				if (ci) {
					a = String(a).toLowerCase();
					b = String(b).toLowerCase();
					const r = a.localeCompare(b);
					if (order === 'asc') return r;
					if (r === 1) return -1;
					if (r === -1) return 1;
					return r;
				}

				if ((a == null) || ((a != null) && (a > b))) {
					return (order === 'asc' ? 1 : -1);
				}
				if ((b == null) || ((b != null) && (a < b))) {
					return (order === 'asc' ? -1 : 1);
				}
				return 0;
			});
		},

		outputs(array) {
			return array;
		}
	},

	'reverse': {
		type: 'array',
		group: 'array',
		value(array) {
			if (array == null) {
				if (this.passthrough) return array;
				return null;
			}

			if (typeCheck(array) !== 'array') throw new Error(valueError(array, "'{{value}}' is not a valid array.", 'Invalid array.'));

			return array.slice().reverse();
		},

		outputs(array) {
			return array;
		}
	},

	'flatten': {
		type: 'array',
		group: 'array',
		value(array) {
			if (array == null) {
				if (this.passthrough) return array;
				return null;
			}

			if (typeCheck(array) !== 'array') throw new Error(valueError(array, "'{{value}}' is not a valid array.", 'Invalid array.'));

			const depth = arguments[1];
			if (!['null', 'undefined', 'number'].includes(typeCheck(depth))) {
				throw new Error(valueError(depth, "'{{value}}' is not a valid number.", 'Invalid number.'));
			}

			const flatten = (array, depth) => {
				if (depth == null) {
					depth = 1;
				}
				return array.reduce((acc, val) => {
					if ((typeCheck(val) === 'array') && (depth > 1)) {
						return acc.concat(flatten(val, depth - 1));
					} else {
						return acc.concat(val);
					}
				}

				, []);
			};

			return flatten(array, depth);
		},

		outputs(array) {
			return array;
		}
	},

	'distinct': _distinct,

	'deduplicate': _distinct,

	'toCollection': {
		type: 'array',
		group: 'array',
		value(array, key, value) {
			if (!(array && key && value)) return {};

			if (!Array.isArray(array)) throw new Error(valueError(array, "'{{value}}' is not a valid array.", 'Invalid array.'));
			if (!['string', 'number'].includes(typeCheck(key))) throw new Error(valueError(key, "'{{value}}' is not a valid key name.", 'Invalid key name.'));
			// eslint-disable-next-line max-len
			if (!['string', 'number'].includes(typeCheck(value))) throw new Error(valueError(value, "'{{value}}' is not a valid value name.", 'Invalid value name.'));

			if (array.length === 0) return {};

			const results = {};

			array.forEach((o) => {
				const k = mapVariable.call(this, o, key);
				const v = mapVariable.call(this, o, value);

				if (k) results[k] = v;
			});

			return results;
		},

		outputs(array) {
			return {
				type: 'collection'
			};
		}
	},

	'toArray': {
		type: 'array',
		group: 'array',
		value(collection) {
			// #metrocode
			// If not a collection or nothing at all, then throw, this is invalid input
			// eslint-disable-next-line max-len
			if (!collection || typeof collection !== 'object') throw new Error(valueError(collection, "'{{value}}' is not a valid collection.", 'Invalid collection.'));
			// If collection is already an array, then return it
			if (Array.isArray(collection)) return collection.map((value, key) => ({key, value}));
			// The actual logic here - iterate over entries an map them to a "key-value" array
			return Object.entries(collection).map(([key, value]) => ({key, value}));
		},

		outputs(array) {
			return {
				type: 'array',
				spec: [
					{
						name: 'key',
						type: 'string'
					},
					{
						name: 'value',
						type: 'collection'
					}
				]
			};
		}
	},

	'omit': {
		type: 'collection',
		group: 'general',
		value(obj, ...keys) {
			if (!obj) return {}
			if (!keys || keys.length === 0) return obj;
			if (Array.isArray(keys[0])) keys = keys[0];
			return Object.keys(obj).reduce((acc, curr) => {
				if (!(keys.includes(curr))) {
					acc[curr] = obj[curr];
				}
				return acc;
			}, {});
		},
		outputs(obj) {
			return {
				type: 'collection'
			}
		}
	},
	
	'pick': {
		type: 'collection',
		group: 'general',
		value(obj, ...keys) {
			if (!obj) return {}
			if (!keys || keys.length === 0) return {};
			if (Array.isArray(keys[0])) keys = keys[0];
			return keys.reduce((acc, curr) => {
				if (Object.keys(obj).includes(curr)) {
					acc[curr] = obj[curr];
				}
				return acc;
			}, {});
		},
		outputs(obj) {
			return {
				type: 'collection'
			}
		}
	},

	'md5': {
		type: 'text',
		group: 'string',
		value(data) {
			if (typeCheck(data) !== 'buffer') data = FUNCTIONS.toString.value(data);
			return crypto.createHash('md5').update(data).digest('hex');
		}
	},

	'sha1': {
		type: 'text',
		group: 'string',
		value(data) {
			let outputEncoding = arguments[1] != null ? arguments[1].toLowerCase() : undefined;
			let key = arguments[2];
			let keyEncoding = arguments[3] != null ? arguments[3].toLowerCase() : undefined;

			if (!['hex', 'latin1', 'base64', 'binary'].includes(outputEncoding)) {
				outputEncoding = 'hex';
			}
			if (!['text', 'hex', 'base64', 'binary'].includes(keyEncoding)) {
				keyEncoding = 'text';
			}
			if (typeCheck(data) !== 'buffer') {
				data = FUNCTIONS.toString.value(data);
			}

			if (key != null) {
				if (keyEncoding === 'text') {
					key = FUNCTIONS.toString.value(key);

				} else if (keyEncoding === 'binary') {
					// it must be a nested if because there should not be any action when keyEncoding is binary and key is buffer
					if (typeCheck(key) !== 'buffer') {
						throw new Error(valueError(keyEncoding, "Key '{{value}}' is not a valid buffer.", 'Invalid key.'));
					}

				} else {
					key = Buffer.from(FUNCTIONS.toString.value(key), keyEncoding);
				}
			}

			if ((key == null)) {
				return crypto.createHash('sha1').update(data).digest(outputEncoding);

			} else {
				return crypto.createHmac('sha1', key).update(data).digest(outputEncoding);
			}
		}
	},

	'sha256': {
		type: 'text',
		group: 'string',
		value(data) {
			let outputEncoding = arguments[1] != null ? arguments[1].toLowerCase() : undefined;
			let key = arguments[2];
			let keyEncoding = arguments[3] != null ? arguments[3].toLowerCase() : undefined;

			if (!['hex', 'latin1', 'base64', 'binary'].includes(outputEncoding)) {
				outputEncoding = 'hex';
			}
			if (!['text', 'hex', 'base64', 'binary'].includes(keyEncoding)) {
				keyEncoding = 'text';
			}
			if (typeCheck(data) !== 'buffer') {
				data = FUNCTIONS.toString.value(data);
			}

			if (key != null) {
				if (keyEncoding === 'text') {
					key = FUNCTIONS.toString.value(key);

				} else if (keyEncoding === 'binary') {
					// it must be a nested if because there should not be any action when keyEncoding is binary and key is buffer
					if (typeCheck(key) !== 'buffer') {
						throw new Error(valueError(keyEncoding, "Key '{{value}}' is not a valid buffer.", 'Invalid key.'));
					}

				} else {
					key = Buffer.from(FUNCTIONS.toString.value(key), keyEncoding);
				}
			}

			if ((key == null)) {
				return crypto.createHash('sha256').update(data).digest(outputEncoding);

			} else {
				return crypto.createHmac('sha256', key).update(data).digest(outputEncoding);
			}
		}
	},

	'sha512': {
		type: 'text',
		group: 'string',
		value(data) {
			let outputEncoding = arguments[1] != null ? arguments[1].toLowerCase() : undefined;
			let key = arguments[2];
			let keyEncoding = arguments[3] != null ? arguments[3].toLowerCase() : undefined;

			if (!['hex', 'latin1', 'base64', 'binary'].includes(outputEncoding)) {
				outputEncoding = 'hex';
			}
			if (!['text', 'hex', 'base64', 'binary'].includes(keyEncoding)) {
				keyEncoding = 'text';
			}
			if (typeCheck(data) !== 'buffer') {
				data = FUNCTIONS.toString.value(data);
			}

			if (key) {
				if (keyEncoding === 'text') {
					key = FUNCTIONS.toString.value(key);

				} else if (keyEncoding === 'binary') {
					// it must be a nested if because there should not be any action when keyEncoding is binary and key is buffer
					if (typeCheck(key) !== 'buffer') {
						throw new Error(valueError(keyEncoding, "Key '{{value}}' is not a valid buffer.", 'Invalid key.'));
					}

				} else {
					key = Buffer.from(FUNCTIONS.toString.value(key), keyEncoding);
				}
			}

			if (!key) {
				return crypto.createHash('sha512').update(data).digest(outputEncoding);
			} else {
				return crypto.createHmac('sha512', key).update(data).digest(outputEncoding);
			}
		}
	},

	'base64': {
		type: 'text',
		group: 'string',
		value(data) {
			if (typeCheck(data) === 'buffer') {
				return data.toString('base64');
			}

			data = FUNCTIONS.toString.value(data);

			if (typeof btoa === 'function') return btoa(data);
			return Buffer.from(data).toString('base64');
		}
	}
};
