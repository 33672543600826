/* eslint-disable no-unused-vars, no-invalid-this */

'use strict';

const {IMLError} = require('./errors.js');
const KEYWORDS = require('./keywords.js');

class FakeBuffer {
	constructor(value) {
		this._value = value;
	}
	static from(value) {
		return new FakeBuffer(value);
	}
	static isBuffer(value) {
		return value instanceof FakeBuffer;
	}
	toString(codepage) {
		return this._value;
	}
}

const browser = typeof window !== 'undefined';
const Buffer = browser ? (window.Buffer || FakeBuffer) : global['Buffer']; // This hack prevents Browserify to add Buffer polyfill

function parseUnknown(buffer, links, errors) {
	if (buffer[0] === '$') {
		// link
		let link;
		const cursor = buffer.indexOf('.');
		if (cursor === -1) {
			// link variable
			link = buffer.substr(1);
			if ((links[link] == null)) {
				errors.push(new IMLError(`Linked source not found '${buffer}'.`));
			}

			buffer = links[link] != null ? links[link] : '?';

		} else {
			if (links != null) {
				// link module
				link = buffer.substr(1, cursor - 1);
				buffer = (links[link] != null ? links[link] : '?') + buffer.substr(cursor);

			} else {
				return {type: 'link', name: buffer};
			}
		}
	}

	if (isNumber(buffer)) {
		// number
		return {type: 'number', value: parseFloat(buffer)};

	} else if (KEYWORDS[buffer]) {
		return {type: 'keyword', name: buffer};

	} else {
		// bundle variable
		return {type: 'variable', name: buffer};
	}
}

function splitVariable(variable) {
	variable = String(variable);
	if (-1 === variable.indexOf('`')) {
		return variable.split('.');
	}

	return variable.split(/\.(?=(?:[^`]*`[^`]*`)*[^`]*$)/g).map(unescape);
}

function mapVariable(data, key) {
	if (!this) throw new Error('Invalid invocation.');

	if (data == null) {
		if (this.passthrough && this.passthrough.null === false) return undefined;
		if (this.passthrough) return data;
		return null;
	}

	key = splitVariable(key);

	while (key.length) {
		let k = key.shift();
		let n = null;

		if (['prototype', 'constructor', '__proto__', '__super__'].includes(k)) {
			return this.passthrough ? undefined : null;
		}

		if (/\[(\d+)?\]$/.exec(k)) {
			n = RegExp.$1 ? parseInt(RegExp.$1) : 1;
			k = k.substr(0, k.length - RegExp.$1.length - 2);
		}

		if ((k === '') && Array.isArray(data) && (n != null)) {
			data = data[n - 1];
		} else {
			if (Array.isArray(data)) {
				k = parseInt(k);
				if (isNaN(k)) return this.passthrough ? undefined : null;
				data = data[k - 1];
			} else if (Object.prototype.hasOwnProperty.call(data, k)) {
				data = data[k];
			} else {
				data = undefined;
			}

			if ('function' === typeof data) {
				return this.passthrough ? undefined : null; // prevent functions to be accessed
			}

			if ((n != null) && Array.isArray(data)) {
				data = data[n - 1];
			}
		}

		if ('function' === typeof data) {
			return this.passthrough ? undefined : null; // prevent functions to be accessed
		}

		if (data == null) {
			if (this.passthrough && this.passthrough.null === false) return undefined;
			if (this.passthrough) return data;
			return null;
		}
	}

	if (data == null) {
		if (this.passthrough && this.passthrough.null === false) return undefined;
		if (this.passthrough) return data;
		return null;
	}
	return data;
}

/**
 * Returns number if string is serializable to number, always use Number(var).
 * 
 * @param {*} variable 
 */

function typeCheck(variable) {
	if (variable === null) return 'null';
	if (typeof variable === 'undefined') return 'undefined';

	switch (typeof variable) {
		case 'number': return 'number';
		case 'boolean': return 'boolean';
		case 'string': return isNumber(variable) ? 'number' : 'string';
		case 'object':
			if (Array.isArray(variable)) {
				return 'array';
			} else if (variable instanceof Date) {
				return 'date';
			} else if (variable instanceof String) {
				return 'string';
			} else if (variable instanceof Number) {
				return 'number';
			} else if (variable instanceof Boolean) {
				return 'boolean';
			} else if (Buffer.isBuffer(variable)) {
				return 'buffer';
			} else {
				return 'object';
			}

		default: return 'unknown'; // Unkonwn type for basic functions
	}
}

function deepEqual(a, b) {
	if ((a == null) || (b == null)) { // if a is null/undefined or b is null/undefined
		return (a != null) === (b != null);
	}

	switch (typeof a) {
		case 'string': case 'number': case 'boolean':
			return String(a) === String(b);
			break;

		case 'object':
			if ('object' !== typeof b) {
				return false;
			}

			if (a instanceof Array) {
				if (!(b instanceof Array)) {
					return false;
				}
				if (a.length !== b.length) {
					return false;
				}
				if (a.length === 0) {
					return true;
				}

				for (let index = 0; index < a.length; index++) {
					const item = a[index];
					if (!deepEqual(item, b[index])) {
						return false;
					}
				}

			} else if (a instanceof Date) {
				if (!(b instanceof Date)) {
					return false;
				}

				return a.getTime() === b.getTime();

			} else {
				const aLength = Object.keys(a).length;
				if (aLength !== Object.keys(b).length) {
					return false;
				}
				if (aLength === 0) {
					return true;
				}

				for (const key in a) {
					if (!deepEqual(a[key], b[key])) {
						return false;
					}
				}
			}
			break;

		default:
			return false; // this should never happen
	}

	return true;
}

function unescape(value) {
	if (value == null) return value;
	if ('string' !== typeof value) return '';

	if ((/^`(.*)`(\[.*\])?$/).exec(value)) {
		value = RegExp.$1.replace(/``/g, '`') + RegExp.$2;
	}

	return value;
}

function escape(value) {
	if (value == null) return value;
	if ('string' !== typeof value) return '';

	if (!(/^[a-z][a-z0-9_]*$/i).test(value)) {
		return `\`${value.replace(/`/g, '``')}\``;
	}

	return value;
}

function escapeable(value) {
	if (value == null) return false;
	if (typeof value !== 'string') return false;
	if (!(/^[a-z][a-z0-9_]*$/i).test(value)) return true;

	return false;
}

function valueError(value, show, hide) {
	const type = typeCheck(value);
	switch (type) {
		case 'number': case 'boolean':
			value = String(value);
			break;
		case 'date':
			value = value.toISOString();
			break;
		case 'array': case 'object': case 'buffer':
			value = `{${type}}`;
			break;
		case 'string':
			if (value.length > 100) {
				return hide;
			}
			break;
		case 'null':
		case 'undefined':
			value = '{empty}';
			break;
		default:
			return hide;
	}

	return show.replace((/\{\{value\}\}/g), value);
}

/**
 * We must take care about concatenating values to string to prevent some unexpected behaviour.
 * 
 * @param {*} item 
 */

function concatenate(item) {
	if (item == null) {
		return '';
	}

	if (typeof item === 'object') {
		if (item instanceof String || item instanceof Boolean || item instanceof Number) {
			return String(item.valueOf());
		}

		if (Array.isArray(item)) {
			return item.map(i => concatenate(i)).join(', ');

		} else if (item instanceof Date) {
			if (isNaN(item)) {
				return null;
			}
			return item.toISOString();

		} else {
			return '[Collection]';
		}

	} else {
		return String(item);
	}
}

function escapeHTML(value) {
	const map = {'&': '&amp;', '<': '&lt;', '>': '&gt;'};
	return String(value).replace(/[&<>]/g, tag => map[tag] || tag);
}

function isNumber(value) {
	return (/^\-?\d+(\.\d+)?$/).test(value);
}

/**
 * This is a getter function for property "name" of AST object type "variable".
 */

function pathToString() {
	// eslint-disable-next-line no-invalid-this
	return this.path.map((item, index) => {
		if (item.type === 'variable') return (index === 0 ? '' : '.') + item.name;
		if (item.type !== 'property') return ''; // This should never happen

		if (item.length === 0) return '[]';
		if (item.length === 1 && item[0].type === 'number') return `[${item[0].value}]`;
		if (item.length === 1 && item[0].type === 'string') return `["${item[0].value.replace(/\"/g, '""')}"]`;
		return '[]'; // Expression resolves to dynamic value, return just an array indicator.
	}).join('');
}

module.exports = {
	Buffer,
	parseUnknown,
	splitVariable,
	mapVariable,
	typeCheck,
	deepEqual,
	unescape,
	escape,
	escapeable,
	valueError,
	concatenate,
	escapeHTML,
	isNumber,
	pathToString
};
