'use strict';

module.exports = {
	'true': {
		type: 'boolean',
		group: 'general',
		value: true
	},

	'false': {
		type: 'boolean',
		group: 'general',
		value: false
	},

	'space': {
		type: 'text',
		group: 'string',
		value: ' '
	},

	'nbsp': {
		type: 'text',
		group: 'string',
		value: String.fromCharCode(160)
	},

	'tab': {
		type: 'text',
		group: 'string',
		value: '\t'
	},

	'emptystring': {
		type: 'text',
		group: 'string',
		value() {
			// We use new String as a special symbol for empty string. Primitive empty strings represent null values.
			return new String(''); // eslint-disable-line no-new-wrappers
		}
	},

	'newline': {
		type: 'text',
		group: 'string',
		value: '\n'
	},

	'carriagereturn': {
		type: 'text',
		group: 'string',
		value: '\r'
	},

	'undefined': {
		type: '*',
		group: 'general',
		value: undefined,
		hidden: true
	},

	'ignore': {
		type: '*',
		group: 'general',
		value: undefined
	},

	'null': {
		type: '*',
		group: 'general',
		value: null
	},

	// Alias of null
	'erase': {
		type: '*',
		group: 'general',
		value: null
	},

	'emptyarray': {
		type: '*',
		group: 'array',
		value() {
			// We need to create empty array for each "emptyarray" occurence to prevent unexpected mutation of the object.
			return [];
		}
	},

	// alias of emptyarray
	'erasearray': {
		type: '*',
		group: 'array',
		value() {
			// We need to create empty array for each "emptyarray" occurence to prevent unexpected mutation of the object.
			return [];
		},
		hidden: true
	}
};
