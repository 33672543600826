'use strict';

module.exports = {
	'exist': { symbol: '?' },
	'notexist': { symbol: '!?' },
	'text:equal': { symbol: '=' },
	'text:equal:ci': { symbol: '=' },
	'text:notequal': { symbol: '!=' },
	'text:notequal:ci': { symbol: '!=' },
	'text:contain': { symbol: '~' },
	'text:contain:ci': { symbol: '~' },
	'text:notcontain': { symbol: '!~' },
	'text:notcontain:ci': { symbol: '!~' },
	'text:startwith': { symbol: '^.' },
	'text:startwith:ci': { symbol: '^.' },
	'text:notstartwith': { symbol: '!^.' },
	'text:notstartwith:ci': { symbol: '!^.' },
	'text:endwith': { symbol: '.$' },
	'text:endwith:ci': { symbol: '.$' },
	'text:notendwith': { symbol: '!.$' },
	'text:notendwith:ci': { symbol: '!.$' },
	'text:pattern': { symbol: '(.*)' },
	'text:pattern:ci': { symbol: '(.*)' },
	'text:notpattern': { symbol: '!(.*)' },
	'text:notpattern:ci': { symbol: '!(.*)' },
	'number:equal': { symbol: '=' },
	'number:notequal': { symbol: '!=' },
	'number:greater': { symbol: '>' },
	'number:less': { symbol: '<' },
	'number:greaterorequal': { symbol: '>=' },
	'number:lessorequal': { symbol: '<=' },
	'date:equal': { symbol: '=' },
	'date:notequal': { symbol: '!=' },
	'date:greater': { symbol: '>' },
	'date:less': { symbol: '<' },
	'date:greaterorequal': { symbol: '>=' },
	'date:lessorequal': { symbol: '<=' },
	'time:equal': { symbol: '=' },
	'time:notequal': { symbol: '!=' },
	'time:greater': { symbol: '>' },
	'time:less': { symbol: '<' },
	'time:greaterorequal': { symbol: '>=' },
	'time:lessorequal': { symbol: '<=' },
	'semver:equal': { symbol: '=' },
	'semver:notequal': { symbol: '!=' },
	'semver:greater': { symbol: '>' },
	'semver:less': { symbol: '<' },
	'semver:greaterorequal': { symbol: '>=' },
	'semver:lessorequal': { symbol: '<=' },
	'array:contain': { symbol: '@>' },
	'array:contain:ci': { symbol: '@>' },
	'array:notcontain': { symbol: '!@>' },
	'array:notcontain:ci': { symbol: '!@>' },
	'array:equal': { symbol: '==' },
	'array:notequal': { symbol: '!=' },
	'array:greater': { symbol: '>' },
	'array:less': { symbol: '<' },
	'array:greaterorequal': { symbol: '>=' },
	'array:lessorequal': { symbol: '<=' },
	'boolean:equal': { symbol: '=' },
	'boolean:notequal': { symbol: '!=' }
};
