/* eslint-disable no-unused-vars */

'use strict';

module.exports = {
	createHash(alg) {
		return {
			update(data) {
				return {digest(enc) {
					return '';
				}};
			}
		};
	},

	createHmac(alg, key) {
		return {
			update(data) {
				return {digest(enc) {
					return '';
				}};
			}
		};
	}
}