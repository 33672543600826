'use strict';

/**
 * IML Error.
 * 
 * @param {String} message Error message.
 * 
 * @property {String} stack Call stack.
 * @property {String} message Error message.
 */

class IMLError extends Error {
	constructor(message) {
		super();

		this.name = 'IMLError';
		this.message = message;

		if (typeof Error.captureStackTrace === 'function') {
			Error.captureStackTrace(this, this.constructor);
		}
	}
}

module.exports = {
	IMLError
};
