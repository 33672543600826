/* eslint-disable new-cap */

'use strict';

const Decimal = require('decimal.js');

/**
 * List of operator precedence: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Operator_Precedence
 */

module.exports = {
	'!': {
		type: 'boolean',
		group: 'general',
		label: 'not',
		precedence: 16,
		hidden: true
	},
	'*': {
		type: 'number',
		group: 'math',
		precedence: 14,
		value(a, b) {
			return new Decimal(Number(a)).times(new Decimal(Number(b))).toNumber();
		}
	},
	'/': {
		type: 'number',
		group: 'math',
		precedence: 14,
		value(a, b) {
			return new Decimal(Number(a)).dividedBy(new Decimal(Number(b))).toNumber();
		}
	},
	'%': {
		type: 'number',
		group: 'math',
		label: 'mod',
		precedence: 14,
		value(a, b) {
			return new Decimal(Number(a)).modulo(new Decimal(Number(b))).toNumber();
		}
	},
	'+': {
		type: 'number',
		group: 'math',
		precedence: 13,
		value(a, b) {
			if (a == null && b == null) return null;
			if (a == null || b == null) {
				if (['number', 'string', 'boolean'].includes(typeof (a != null ? a : b))) {
					return a != null ? a : b;
				}
			}

			if (typeof a === 'number' && typeof b === 'number') {
				return new Decimal(a).plus(new Decimal(b)).toNumber();
			}

			return a + b;
		}
	},
	'++': {
		type: 'number',
		group: 'math',
		precedence: 13,
		value(a, b) {
			return new Decimal(Number(a)).plus(new Decimal(Number(b))).toNumber();
		}
	},
	'-': {
		type: 'number',
		group: 'math',
		precedence: 13,
		value(a, b) {
			return new Decimal(Number(a)).minus(new Decimal(Number(b))).toNumber();
		}
	},
	'<': {
		type: 'boolean',
		group: 'math',
		precedence: 11,
		value(a, b) {
			return a < b;
		}
	},
	'<=': {
		type: 'boolean',
		group: 'math',
		precedence: 11,
		value(a, b) {
			return a <= b;
		}
	},
	'>': {
		type: 'boolean',
		group: 'math',
		precedence: 11,
		value(a, b) {
			return a > b;
		}
	},
	'>=': {
		type: 'boolean',
		group: 'math',
		precedence: 11,
		value(a, b) {
			return a >= b;
		}
	},
	'=': {
		type: 'boolean',
		group: 'general',
		precedence: 10,
		value(a, b) {
			return a == b;
		}
	},
	'!=': {
		type: 'boolean',
		group: 'general',
		precedence: 10,
		value(a, b) {
			return a != b;
		}
	},
	'&': {
		type: 'boolean',
		group: 'general',
		label: 'and',
		precedence: 6,
		value(a, b) {
			return a && b;
		}
	},
	'|': {
		type: 'boolean',
		group: 'general',
		label: 'or',
		precedence: 5,
		value(a, b) {
			return a || b;
		}
	}
};
