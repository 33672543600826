/* eslint-disable no-invalid-this */
'use strict';

const {IMTDate} = require('@integromat/dateparser');
const {typeCheck} = require('./utils.js');
const semver = require('semver');

function compare(a, b, operator) {
	const category = operator.split(':');

	switch (category[0]) {
		case 'exist':
			return (a != null) && (a !== '');

		case 'notexist':
			return !((a != null) && (a !== ''));

		case 'text':
			a = String(a != null ? a : '');
			b = String(b != null ? b : '');

			if (category[2] === 'ci') {
				a = a.toLowerCase();
				b = b.toLowerCase();
			}

			switch (category[1]) {
				case 'equal': return a === b;
				case 'notequal': return a !== b;
				case 'contain': return a.indexOf(b) !== -1;
				case 'notcontain': return a.indexOf(b) === -1;
				case 'startwith': return a.indexOf(b) === 0;
				case 'notstartwith': return a.indexOf(b) !== 0;
				case 'endwith':
					if (a.lastIndexOf(b) === -1) {
						return false;
					}
					return a.lastIndexOf(b) === (a.length - b.length);
				case 'notendwith':
					if (a.lastIndexOf(b) === -1) {
						return true;
					}
					return a.lastIndexOf(b) !== (a.length - b.length);
				case 'pattern':
					try {
						return new RegExp(b).test(a);
					} catch (ex) {
						return false;
					}
				case 'notpattern':
					try {
						return !new RegExp(b).test(a);
					} catch (ex) {
						return true;
					}
				default: return false;
			}

		case 'number':
		case 'time':
			if (category[0] === 'time') {
				a = IMTDate.parseTime(a, this.timezone);
				b = IMTDate.parseTime(b, this.timezone);
			}

			if ((a == null) || (a === '')) {
				a = NaN;
			}
			if ((b == null) || (b === '')) {
				b = NaN;
			}

			a = Number(a);
			b = Number(b);

			if (isNaN(a || isNaN(b))) {
				return category[1] === 'notequal';
			}

			switch (category[1]) {
				case 'equal': return a === b;
				case 'notequal': return a !== b;
				case 'greater': return a > b;
				case 'less': return a < b;
				case 'greaterorequal': return a >= b;
				case 'lessorequal': return a <= b;
				default: return false;
			}

		case 'date':
			a = IMTDate.parse(a, this.timezone);
			b = IMTDate.parse(b, this.timezone);

			if ((a == null) && (b == null)) {
				return category[1] === 'notequal';
			}

			if (!(a instanceof Date) || !(b instanceof Date)) {
				return category[1] === 'notequal';
			}

			if (isNaN(a.getTime() || isNaN(b.getTime()))) {
				return category[1] === 'notequal';
			}

			switch (category[1]) {
				case 'equal': return a.getTime() === b.getTime();
				case 'notequal': return a.getTime() !== b.getTime();
				case 'greater': return a.getTime() > b.getTime();
				case 'less': return a.getTime() < b.getTime();
				case 'greaterorequal': return a.getTime() >= b.getTime();
				case 'lessorequal': return a.getTime() <= b.getTime();
				default: return false;
			}

		case 'semver':
			try {
				a = semver.parse(a);
				b = semver.parse(b);
			} catch (_) {
				return false;
			}

			if ((a == null) || (b == null)) {
				return false;
			}

			switch (category[1]) {
				case 'equal': return semver.eq(a, b);
				case 'notequal': return semver.neq(a, b);
				case 'greater': return semver.gt(a, b);
				case 'less': return semver.lt(a, b);
				case 'greaterorequal': return semver.gte(a, b);
				case 'lessorequal': return semver.lte(a, b);
				default: return false;
			}

		case 'array':
			if (['contain', 'notcontain'].includes(category[1])) {
				if ((a == null) || (b == null)) {
					return false;
				}
				if (typeCheck(a) !== 'array') {
					return false;
				}

				const type = typeCheck(b);
				if ((type === 'string') && (category[2] === 'ci')) {
					b = b.toLowerCase();
				}

				const cont = a.some((item) => {
					switch (type) {
						case 'string': case 'number':
							switch (typeCheck(item)) {
								case 'string': case 'number':
									if (category[2] === 'ci') {
										item = item.toLowerCase();
									}

									return String(item) === String(b);

								default:
									return false;
							}

						case 'date':
							if (typeCheck(b) === 'date') {
								return +item === +b;

							} else {
								return false;
							}

						default:
							return item === b;
					}
				});

				if (category[1] === 'notcontain') {
					return !cont;
				}
				return cont;
			}

			if (Array.isArray(a)) {
				a = a.length;
			}

			if (Array.isArray(b)) {
				b = b.length;
			}

			return compare.call(this, a, b, `number:${category[1]}`);

		case 'boolean':
			if (typeCheck(a) === 'string') {
				a = String(a);
				a = !((a === '') || (a === 'false'));
			} else if ('boolean' !== typeof a) {
				a = (a != null);
			}

			if (typeCheck(b) === 'string') {
				b = String(b);
				b = !((b === '') || (b === 'false'));
			} else if ('boolean' !== typeof b) {
				b = (b != null);
			}

			switch (category[1]) {
				case 'equal':
					return a === b;
				case 'notequal':
					return a !== b;
				default:
					return false;
			}

		case 'object':
			if ((a == null) && (b == null)) return true;
			if ((a == null) || (b == null)) return false;
			if (typeof(a) !== 'object') return false;
			if (typeof(a) !== typeof(b)) return false;
			break;

		default: return false;
	}
}

module.exports = compare;
